import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { ActivityTableHeader } from '..';
import {
  ShipperOrderActivityEventType,
  SOActivityType,
} from '../../../constant';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import { JOActivityInfo } from '../../../model/JobOrder.model';
import { getFullDateTimeFormat } from '../../../util/date.util';
import { getActivityEventTypeLabel } from '../../../util/shipperOrder.util';
import { Icon, IconButton, Text } from '../../atom';
import { CircleInactive } from '../../atom/Icon/Icon.atom';
import ActivityTableBody from '../ActivityTableBody/ActivityTableBody.molecule';
import { TableHeaderItem } from '../ActivityTableHeader/ActivityTableHeader.molecule';

// #region INTERFACES
type Props = {
  index: number;
  translator: UseTranslator;
  activity: JOActivityInfo;
  activityTypeLabel: string;
  activityTableHeaders: TableHeaderItem[];
  onImagesClick(photos: Photo[], index: number, activityIndex: number): void;
  onDownloadClick(photos: Photo[]): void;
};
// #endregion

// #region STYLED
const RootContainer = tw.section`flex flex-col mb-2.5`;
const GalleryHeaderContainer = tw.div`flex flex-1 justify-between items-center animate-[slide-in-left 0.25s ease-in-out both]
mb-2`;

const ActivityContainer = styled.div<{ type?: SOActivityType | null }>(
  ({ type }) => [
    tw`flex flex-col py-2.5 mb-2.5 px-5 rounded-md border-2 animate-[slide-in-left 0.25s ease-in-out both]`,
    type === SOActivityType.PICK_UP &&
      tw`bg-ocean-bg border-ocean-text text-ocean-text`,
    type === SOActivityType.DROP_OFF &&
      tw`bg-blue-light border-twilight-text text-twilight-text`,
    type === SOActivityType.STAND_BY &&
      tw`bg-lime-bg border-lime-border text-lime-border`,
  ],
);
const ActivityDivider = tw.div`border-r-2 min-height[1.5rem] border-dashed border-grey-four`;
const ActivityLabelContainer = tw.div`w-40`;
const ActivityTopContainer = tw.div`flex flex-row justify-between`;
const ActivityTopText = styled.h6<{ type?: SOActivityType | null }>(
  ({ type }) => [
    tw`text-[14px] leading-[24px] font-weight[600] truncate`,
    type === SOActivityType.PICK_UP && tw`text-ocean-text`,
    type === SOActivityType.DROP_OFF && tw`text-twilight-text`,
    type === SOActivityType.STAND_BY && tw`text-lime-border`,
  ],
);
const ActivityFinishedContainer = tw.div`flex flex-row -mt-1`;
const ActivityFinishedSuccessCircle = styled.div<{ show: boolean }>(
  ({ show }) => [
    tw`invisible w-4 rounded-full bg-status-success h-4`,
    show && tw`visible`,
  ],
);
const ActivityStatusContainer = tw.div`flex flex-col items-center w-6 self-center mx-2`;
const ActivityNoteContainer = tw.div`flex flex-col flex-grow whitespace-pre-line max-width[366px]`;
const ActivityGoodsContainer = styled.div<{ type?: SOActivityType | null }>(
  ({ type }) => [
    tw`py-2.5 mb-2.5 px-5 rounded-md border-2 animate-slide-in-left`,
    type === SOActivityType.PICK_UP && tw`border-ocean-text`,
    type === SOActivityType.DROP_OFF && tw`border-twilight-text`,
    type === SOActivityType.STAND_BY && tw`border-lime-border`,
  ],
);

const ActivityIconContainer = tw.div`mx-1 w-4 rounded-full flex justify-center`;

const ActivityTable = tw.table`w-full table border-spacing[12px]`;
// #endregion

export default function JODetailSOActivityItem({
  index,
  translator,
  activity,
  activityTypeLabel,
  activityTableHeaders,
  onImagesClick,
  onDownloadClick,
}: Props) {
  const activityType = activity?.type || activity.soActivity?.type;
  const activityLocationAddress =
    activity.soActivity?.location?.address || activity.location?.address;
  const activityGoods = activity.soActivity?.goods;
  const activityLocationName =
    activity.location?.name || activity.soActivity?.location?.name;
  const activityPhotos = useMemo(
    () => activity.photos.map((item) => item.photo),
    [activity.photos],
  );
  return (
    <RootContainer>
      <Text.HeadingFour tw="text-[21px] animate-[slide-in-left 0.25s ease-in-out both] mb-2.5">
        {translator.translate('Activity')} {index} • {activityTypeLabel}{' '}
        {!!activityGoods?.length &&
          `• ${activityGoods?.length} ${translator.translate('Goods')}`}
      </Text.HeadingFour>

      <ActivityContainer type={activityType}>
        <ActivityTopContainer>
          <ActivityTopText tw="flex-shrink" type={activityType}>
            {activityLocationName}
          </ActivityTopText>

          {activity.soActivity?.expectedFinishAt && (
            <ActivityTopText tw="flex-shrink-0" type={activityType}>
              {translator.translate('Expected at:')}{' '}
              {getFullDateTimeFormat(
                activity.soActivity.expectedFinishAt * 1000,
                true,
              )}
            </ActivityTopText>
          )}
        </ActivityTopContainer>

        <Text.Label tw="mb-2.5 overflow-wrap[anywhere] whitespace-normal">
          {activityLocationAddress}
        </Text.Label>

        {activity.events?.map((event, idx, arr) => {
          const isLastData = idx === arr.length - 1;
          const hasNoteDescription =
            event.eventType === ShipperOrderActivityEventType.NOTES_ADDED;
          const noteTitle = `${translator.translate('Note')} #${
            activity.notes
              ?.map((_note, _noteIdx) => ({
                ..._note,
                number: _noteIdx + 1,
              }))
              ?.find((_note) => _note.createdAt === event.eventTime)?.number ??
            ''
          }`;

          const noteDescription =
            activity.notes
              ?.find((_note) => _note.createdAt === event.eventTime)
              ?.notes.trim() ?? '';

          return (
            <ActivityFinishedContainer key={`${noteTitle}-${event.eventTime}`}>
              <ActivityLabelContainer>
                <Text.Label>
                  {getFullDateTimeFormat(event.eventTime * 1000, true)}
                </Text.Label>
              </ActivityLabelContainer>

              <ActivityStatusContainer>
                {idx === 0 ? (
                  <ActivityFinishedSuccessCircle show />
                ) : (
                  <ActivityIconContainer>
                    <CircleInactive />
                  </ActivityIconContainer>
                )}
                {!isLastData && (
                  <ActivityDivider
                    css={[
                      hasNoteDescription &&
                        noteDescription.length <= 60 &&
                        tw`height[3rem]`,
                      hasNoteDescription &&
                        noteDescription.length > 60 &&
                        noteDescription.length <= 100 &&
                        tw`height[4rem]`,
                      hasNoteDescription &&
                        noteDescription.length > 100 &&
                        noteDescription.length <= 200 &&
                        tw`height[8rem]`,
                      hasNoteDescription &&
                        noteDescription.length > 200 &&
                        noteDescription.length <= 260 &&
                        tw`height[10rem]`,
                    ]}
                  />
                )}
              </ActivityStatusContainer>

              {hasNoteDescription ? (
                <ActivityNoteContainer>
                  <Text.Label tw="font-bold">
                    {getActivityEventTypeLabel({
                      type: event.eventType,
                      locationName: activityLocationName,
                      noteTitle,
                      translator,
                    })}
                  </Text.Label>
                  <Text.Label tw="overflow-wrap[break-word]">
                    {noteDescription}
                  </Text.Label>
                </ActivityNoteContainer>
              ) : (
                <Text.Label tw="font-bold truncate">
                  {getActivityEventTypeLabel({
                    type: event.eventType,
                    locationName: activityLocationName,
                    noteTitle,
                    translator,
                  })}
                </Text.Label>
              )}
            </ActivityFinishedContainer>
          );
        })}
      </ActivityContainer>

      {activityPhotos?.length > 0 && (
        <ActivityGoodsContainer type={activityType}>
          <GalleryHeaderContainer>
            <Text.HeadingFive tw="block ">
              {translator.translate('Proof of Activity')}
            </Text.HeadingFive>
            <IconButton
              tw="rounded-sm border border-beige-lines flex"
              onClick={() => onDownloadClick(activityPhotos)}
            >
              <Icon.Download.Small />
            </IconButton>
          </GalleryHeaderContainer>
          <div tw="flex">
            {activityPhotos?.map(({ id, accessUrl, fileName }, photoIndex) => (
              <button
                type="button"
                onClick={() => onImagesClick(activityPhotos, photoIndex, index)}
                key={id + accessUrl}
                tw="h-24 w-24 flex justify-center mr-2 rounded overflow-hidden bg-black "
                css={[
                  activity.photos &&
                    photoIndex >= activity.photos.length - 1 &&
                    tw`mr-0`,
                ]}
              >
                <img
                  src={accessUrl}
                  alt={fileName}
                  tw="h-24 w-24 object-cover"
                />
              </button>
            ))}
          </div>
        </ActivityGoodsContainer>
      )}

      {!(activityType === SOActivityType.STAND_BY) && (
        <ActivityGoodsContainer type={activityType}>
          <ActivityTable>
            <ActivityTableHeader
              status={activityType || ''}
              items={activityTableHeaders}
            />
            <ActivityTableBody
              status={activityType || ''}
              items={
                activityGoods
                  ? activityGoods?.map((good) => ({
                      type: translator.translate(capitalize(good.type)),
                      description: good.description,
                      qty: good.quantity,
                      unit: good.uom,
                      weight: good.weight,
                      volume: good.volume,
                    }))
                  : []
              }
            />
          </ActivityTable>
        </ActivityGoodsContainer>
      )}
    </RootContainer>
  );
}
