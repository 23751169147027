import React from 'react';
import tw, { styled, theme } from 'twin.macro';
import { ExtraStyle } from '../../Type.component';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator.atom';
import { HeadingFive } from '../Text/Text.atom';

interface ButtonBaseProps {
  testID?: string;
  disabled?: boolean;
  small?: boolean;
  feature?: 'flat' | 'rounded';
  hasOutline?: boolean;
}

interface IconButtonProps extends ButtonBaseProps {
  isLoading?: boolean;
  label: string;
  icon: JSX.Element;
  action: () => void;
  hasOutline?: boolean;
  containerStyle?: ExtraStyle;
  labelStyle?: ExtraStyle;
}

const buttonStyles = {
  base: tw`rounded font-semibold items-center justify-center inline-flex space-x-2 select-none cursor-pointer duration-200`,
  sizes: {
    medium: tw`text-[16px] leading-[24px] py-2.5 px-4`,
    small: tw`text-[14px] leading-[24px] py-1 px-4`,
  },
  variants: {
    solid: {
      default: tw`border-0 text-white bg-orange hover:bg-orange-hover-button active:bg-orange-hover-button`,
      disabled: tw`bg-grey-four cursor-default text-white`,
    },
    outlined: {
      default: tw`text-black box-border border border-solid border-beige-lines bg-transparent hover:(bg-orange-hover) active:(border-beige-lines bg-orange-hover)`,
      disabled: tw`box-border border border-solid border-grey-four text-grey-four cursor-default`,
    },
    text: {
      default: tw`text-orange border-none bg-transparent hover:opacity-70`,
      disabled: tw`text-grey-four cursor-default`,
    },
  },
};

const LoadingContainer = tw.div`px-5 ml-2 flex`;

const IconButtonWrapper = styled.div<ButtonBaseProps>(
  ({ disabled, hasOutline }) => [
    buttonStyles.base,
    !disabled && hasOutline && buttonStyles.variants.outlined.default,
    disabled && hasOutline && buttonStyles.variants.outlined.disabled,
  ],
);

const IconButtonContainer = tw.div`inline-grid grid-cols-[26px 1fr] gap-2 w-max`;

const IconContainer = styled.div`
  & svg {
    width: 20px;
    height: 20px;
  }
`;

export const Solid = styled.button<ButtonBaseProps>(({ small, disabled }) => [
  buttonStyles.base,
  small ? buttonStyles.sizes.small : buttonStyles.sizes.medium,
  !disabled && buttonStyles.variants.solid.default,
  disabled && buttonStyles.variants.solid.disabled,
]);

export const Outlined = styled.button<ButtonBaseProps>(
  ({ small, disabled }) => [
    buttonStyles.base,
    small ? buttonStyles.sizes.small : buttonStyles.sizes.medium,
    !disabled && buttonStyles.variants.outlined.default,
    disabled && buttonStyles.variants.outlined.disabled,
  ],
);

export const Text = styled.button<ButtonBaseProps>(({ small, disabled }) => [
  buttonStyles.base,
  small ? buttonStyles.sizes.small : buttonStyles.sizes.medium,
  tw`p-0`,
  !disabled && buttonStyles.variants.text.default,
  disabled && buttonStyles.variants.text.disabled,
]);

export function Icon({
  label,
  icon,
  action,
  disabled,
  hasOutline,
  containerStyle,
  isLoading,
  labelStyle,
  testID,
}: IconButtonProps) {
  const handleClick = !disabled && !isLoading ? action : undefined;

  return (
    <IconButtonWrapper
      data-testid={testID}
      disabled={disabled}
      hasOutline={hasOutline}
      css={containerStyle}
      onClick={handleClick}
    >
      {isLoading ? (
        <LoadingContainer data-testid="loading-indicator">
          <LoadingIndicator size="small" color={theme`colors.white`} />
        </LoadingContainer>
      ) : (
        <Text disabled={disabled} tw="text-orange">
          <IconButtonContainer>
            <IconContainer
              css={[tw`flex items-center`, disabled && tw`text-grey-four`]}
            >
              {icon}
            </IconContainer>
            <HeadingFive
              css={
                labelStyle || [tw`text-orange`, disabled && tw`text-grey-four`]
              }
            >
              {label}
            </HeadingFive>
          </IconButtonContainer>
        </Text>
      )}
    </IconButtonWrapper>
  );
}
