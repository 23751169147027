import React from 'react';
import tw, { styled } from 'twin.macro';
import {
  IJOContentData,
  IJOSOListData,
  SheetContent,
} from '../../../hook/jobOrderDetail/useJODetailSheet.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { JOActivityInfo } from '../../../model/JobOrder.model';
import { getJODeliveryStatusLabel } from '../../../util/shipperOrder.util';
import { Icon } from '../../atom';
import {
  BodyFourteen,
  HeadingFour,
  Paragraph,
} from '../../atom/Text/Text.atom';
import {
  CollapsiblePanel,
  ShipperOrderStatus,
  WrapTextContainer,
} from '../../molecule';

// #region STYLED
const SOCard = tw.div`p-4 rounded flex flex-col shadow`;

const TimelineContentContainer = tw.div`ml-4`;
const TimelineContainer = tw.div`relative w-full mt-2`;
const TimelineLocation = tw.div`relative pl-2 ml-2 pb-2 border-l border-dashed border-l-assigned-dark  last-of-type:border-0 last-of-type:pb-0`;
const TimelineIconContainer = styled.div<{
  isSelected?: boolean;
  isPickup: boolean;
}>(({ isSelected, isPickup }) => [
  tw`absolute top-0 left[-8.7px] bg-white text-status-alert`,
  isSelected && tw`bg-orange-hover`,
  isPickup && tw`text-grey-two`,
]);

// #endregion

// #region INTERFACES

type Props = {
  translator: UseTranslator;
  showContent: SheetContent;
  joContentData: IJOContentData[];
  soListContentData: IJOSOListData[];
};

type TimelineProps = {
  translator: UseTranslator;
  activitiesItem: JOActivityInfo[];
  isPickup?: boolean;
};

// #endregion

function TimelineContentItem({
  translator,
  activitiesItem,
  isPickup,
}: TimelineProps) {
  return (
    <TimelineLocation>
      <TimelineIconContainer isPickup={!!isPickup}>
        <Icon.NavMaps width={16} />
      </TimelineIconContainer>

      <TimelineContentContainer>
        <Paragraph tw="font-bold text-[12px] truncate">
          {activitiesItem.length > 1 && activitiesItem.length}{' '}
          {isPickup
            ? translator.translate('Pickup Location')
            : translator.translate('Drop-off Location')}
        </Paragraph>

        {activitiesItem.slice(0, 3).map((item) => (
          <Paragraph key={item.id} tw="text-[12px] truncate">
            {item.location?.name || item.soActivity?.location?.name}
          </Paragraph>
        ))}
        {activitiesItem.length > 3 && (
          <BodyFourteen>
            {`${activitiesItem.length - 3} ${translator.translate(
              'more locations...',
            )}`}
          </BodyFourteen>
        )}
      </TimelineContentContainer>
    </TimelineLocation>
  );
}

export default function JODetailMobileContent({
  translator,
  showContent,
  joContentData,
  soListContentData,
}: Props) {
  if (showContent === SheetContent.OVERVIEW)
    return (
      <CollapsiblePanel title={translator.translate('JO Detail')}>
        <div tw="flex flex-col gap-[3px]">
          {joContentData.map((item) => (
            <div key={item.value}>
              <div>
                <BodyFourteen tw="text-grey-three">
                  {translator.translate(item.label)}
                </BodyFourteen>
              </div>
              <WrapTextContainer>
                <BodyFourteen>{item.value}</BodyFourteen>
              </WrapTextContainer>
            </div>
          ))}
        </div>
      </CollapsiblePanel>
    );

  return (
    <div tw="flex flex-col gap-2">
      {soListContentData.map((item) => (
        <SOCard
          key={`${item.id}-${item.number}`}
          onClick={(e) => {
            e.stopPropagation();
            item.handleClickActivities();
          }}
        >
          <div tw="flex justify-between">
            <div tw="flex flex-col flex-1 mr-2">
              <WrapTextContainer maxLine={1}>
                <HeadingFour
                  onClick={(e) => {
                    e.stopPropagation();
                    item.handleClickActivities();
                  }}
                  tw="text-orange "
                >
                  {item.number}
                </HeadingFour>
              </WrapTextContainer>
              <WrapTextContainer maxLine={1}>
                <BodyFourteen>{item.shipperName}</BodyFourteen>
              </WrapTextContainer>
            </div>
            <div>
              <ShipperOrderStatus
                deliveryStatus={item.status}
                statusLabel={translator.translate(
                  getJODeliveryStatusLabel(item.status),
                )}
              />
            </div>
          </div>

          <TimelineContainer>
            {item.activities.pickUpActivities.length > 0 && (
              <TimelineContentItem
                translator={translator}
                activitiesItem={item.activities.pickUpActivities}
                isPickup
              />
            )}

            {item.activities.dropOffActivities.length > 0 && (
              <TimelineContentItem
                translator={translator}
                activitiesItem={item.activities.dropOffActivities}
              />
            )}
          </TimelineContainer>
        </SOCard>
      ))}
    </div>
  );
}
