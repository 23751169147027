import React from 'react';
import tw from 'twin.macro';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button } from '../../atom';
import { CloseOutlined } from '../../atom/Icon/Icon.atom';
import { BodySixteen, HeadingThree } from '../../atom/Text/Text.atom';
// #region STYLING
const Container = tw.div`flex flex-col`;
const Content = tw.div`p-5 flex flex-col gap-2.5 border-b border-b-beige-lines`;
const Action = tw.div`p-5 flex justify-between`;
const TitleContainer = tw.div`flex gap-2.5 items-center`;
const Title = tw(HeadingThree)`text-xl tracking-[-.5px]`;
const DescriptionContainer = tw.div`flex`;
const Description = tw(
  BodySixteen,
)`font-black text-orange cursor-pointer ml-1.5 mr-0.5`;
// #endregion

// #region INTERFACE
type DriverErrorModelProps = {
  titleLabel?: string;
  descriptionLabel?: string;
  callCenterLabel?: string;
  backLabel?: string;
  tryAgainLabel?: string;
  handleTryAgain: () => void;
  handleBack: () => void;
  handleCallCenter: () => void;
};
// #endregion

export default function DriverErrorModal({
  titleLabel = 'Failed to Add Driver and Schedule',
  descriptionLabel = 'Please try again later or contact',
  callCenterLabel = 'Call Center',
  backLabel = 'Back to Driver List',
  tryAgainLabel = 'Try Again',
  handleTryAgain,
  handleCallCenter,
  handleBack,
}: DriverErrorModelProps) {
  const translator = useTranslator();
  return (
    <Container>
      <Content>
        <TitleContainer>
          <CloseOutlined />
          <Title>{translator.translate(titleLabel)}</Title>
        </TitleContainer>
        <DescriptionContainer>
          <BodySixteen>
            {translator.translate(descriptionLabel)}
            <Description onClick={handleCallCenter}>
              {translator.translate(callCenterLabel)}
            </Description>
            .
          </BodySixteen>
        </DescriptionContainer>
      </Content>
      <Action>
        <Button.Outlined onClick={handleBack}>
          {translator.translate(backLabel)}
        </Button.Outlined>
        <Button.Solid onClick={handleTryAgain}>
          {translator.translate(tryAgainLabel)}
        </Button.Solid>
      </Action>
    </Container>
  );
}
