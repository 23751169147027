import React, { ReactNode, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { RedirectUrlTarget } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { Divider, Icon, Text } from '../../atom';

// #region STYLE
const Container = styled.div(({ isSelected }: { isSelected?: boolean }) => [
  tw`relative pl-7 pr-3 text-grey-two rounded-tr-lg rounded-br-lg py-2 hover:bg-orange-hover`,
  isSelected && tw`text-orange`,
]);
const SubContainer = styled.ul(({ expanded }: { expanded: boolean }) => [
  tw`duration-200`,
  !expanded && tw`h-0 overflow-hidden `,
]);

const ItemContainer = tw.div`flex items-center space-x-3 cursor-pointer overflow-hidden`;
const ItemContainerHref = tw.a`flex items-center space-x-3 cursor-pointer overflow-hidden`;
const ItemContainerLink = tw(
  Link,
)`flex items-center space-x-3 cursor-pointer overflow-hidden`;
const ItemIcon = tw.div`w-6 flex-shrink-0 items-center h-full`;
const ItemLabel = tw(
  Text.HeadingFive,
)`text-current flex-auto leading-4 flex-basis[0] overflow-hidden flex-wrap min-w-[150px]`;
const ItemSubIcon = styled.span(({ expanded }: { expanded: boolean }) => [
  tw`absolute left-2 top[calc(50% - 7px)]`,
  expanded && tw`duration-200 transform[rotate(90deg)]`,
]);
const DividerContainer = styled.div(
  ({ expandedSidebar }: { expandedSidebar?: boolean }) => [
    tw`p-4`,
    expandedSidebar && tw`pl-16 pr-0`,
  ],
);
const EventLabel = tw.p`py-0.5 px-1 text-[10px] leading-[18px] tracking-[0.5px] rounded font-bold text-white bg-orange`;
// #endregion

type NavProps = {
  onClick(path: string): void;
  path: string;
  externalPath?: string;
  isSelected: boolean;
  label?: string;
  icon?: React.ReactNode;
  testID?: string;
  event?: {
    label: string;
  };
};

type SidebarItemProps = NavProps & {
  expandedSidebar?: boolean;
  hasSub?: NavProps[] | undefined;
  isLink?: boolean;
  hasDivider?: boolean;
  subHeightMultiplier?: number;
};

type ItemProps = Omit<SidebarItemProps, 'hasDivider'> & {
  expandSubMenu?: boolean;
  setExpandSubMenu?(expanded: boolean): void;
};

type ItemLinkProps = {
  path: string;
  icon: ReactNode;
  label?: string;
  hasSub?: NavProps[] | undefined;
  externalPath?: string;
  isLink?: boolean;
};

// #region RENDER ITEM

function ItemLink({
  path,
  isLink,
  icon,
  label,
  hasSub,
  externalPath,
}: ItemLinkProps) {
  if (externalPath)
    return (
      <ItemContainerHref href={externalPath} target={RedirectUrlTarget.BLANK}>
        {!!icon && <ItemIcon>{icon}</ItemIcon>}
        {label && <ItemLabel>{label}</ItemLabel>}
      </ItemContainerHref>
    );
  if (!hasSub?.length && isLink)
    return (
      <ItemContainerLink to={path}>
        {!!icon && <ItemIcon>{icon}</ItemIcon>}
        {label && <ItemLabel>{label}</ItemLabel>}
      </ItemContainerLink>
    );
  return (
    <ItemContainer>
      {!!icon && <ItemIcon>{icon}</ItemIcon>}
      {label && <ItemLabel>{label}</ItemLabel>}
    </ItemContainer>
  );
}

function Item({
  isSelected,
  hasSub,
  path,
  testID,
  icon,
  label,
  expandSubMenu,
  expandedSidebar,
  setExpandSubMenu,
  onClick,
  externalPath,
  isLink = true,
  event,
}: ItemProps) {
  return (
    <Container
      key={`NavItemContainer_${testID as string}`}
      isSelected={isSelected}
      css={[
        isSelected && !hasSub && tw`bg-orange-hover`,
        event && tw`flex items-center`,
      ]}
      data-testid={testID}
      onClick={() => {
        if (isLink) return;
        if (hasSub && typeof setExpandSubMenu === 'function') {
          setExpandSubMenu(!expandSubMenu);
          return;
        }
        onClick(path);
      }}
    >
      <ItemLink
        isLink={isLink}
        path={path}
        icon={icon}
        label={label}
        hasSub={hasSub}
        externalPath={externalPath}
      />

      {expandedSidebar && event && <EventLabel>{event.label}</EventLabel>}

      {hasSub?.length && (
        <ItemSubIcon expanded={!!expandSubMenu}>
          <Icon.ChevronSharp
            fill="none"
            width={16}
            height={16}
            stroke="black"
          />
        </ItemSubIcon>
      )}
    </Container>
  );
}
// #endregion

export default function SidebarItem({
  onClick,
  isSelected,
  icon,
  path,
  label,
  testID,
  hasDivider,
  hasSub,
  expandedSidebar = false,
  externalPath,
  isLink = true,
  event,
}: SidebarItemProps) {
  const translator = useTranslator();

  const [expandSubMenu, setExpandSubMenu] = useState<boolean>(isSelected);

  useEffect(() => {
    setExpandSubMenu(isSelected);
  }, [isSelected]);

  return (
    <>
      <Item
        onClick={onClick}
        isSelected={isSelected}
        hasSub={hasSub}
        icon={icon}
        path={path}
        testID={testID}
        expandSubMenu={expandSubMenu}
        setExpandSubMenu={setExpandSubMenu}
        externalPath={externalPath}
        isLink={isLink && !hasSub?.length}
        label={label ? translator.translate(label) : undefined}
        event={event}
        expandedSidebar={expandedSidebar}
      />

      {hasSub?.length && (
        <SubContainer expanded={expandSubMenu}>
          {expandSubMenu &&
            hasSub.map((item) => (
              <Item
                key={item.path}
                onClick={onClick}
                isSelected={item.isSelected}
                icon={item.icon}
                path={item.path}
                label={
                  item.label ? translator.translate(item.label) : undefined
                }
                testID={item.testID}
                expandedSidebar={expandedSidebar}
              />
            ))}
        </SubContainer>
      )}

      {hasDivider && testID && (
        <DividerContainer
          key={`SidebarItemDivider-${testID}`}
          expandedSidebar={expandedSidebar}
        >
          <Divider />
        </DividerContainer>
      )}
    </>
  );
}
