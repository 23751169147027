import { Photo } from './Common.model';
import { DriverStatus } from './Driver.model';

export enum DrivingContestStatusFilter {
  READY = 'READY',
  NOT_READY = 'NOT_READY',
}

export enum DrivingContestStatus {
  NOT_READY = 101,
  READY = 201,
}

export enum DrivingContestOrderBy {
  DRIVER_NAME_ASC = 'DRIVER_NAME_ASC', // Full Name A-Z (default)
  DRIVER_NAME_DESC = 'DRIVER_NAME_DESC', // Full Name Z-A
  RECENTLY_ADDED_DESC = 'RECENTLY_ADDED_DESC', // Recently Added
}
export enum DrivingContestRegisterOrderBy {
  DRIVER_NAME_ASC = 'DRIVER_NAME_ASC', // Full Name A-Z (default)
  DRIVER_NAME_DESC = 'DRIVER_NAME_DESC', // Full Name Z-A
  CREATED_AT_DESC = 'CREATED_AT_DESC', // Recently Added
}

// TODO: adjust when API ready

export interface DriverDrivingContestProps {
  isRegistered: boolean;
  registeredAt: number;
  status: DrivingContestStatus;
  statusText: keyof typeof DrivingContestStatus;
}
export interface DrivingContest {
  id: string;
  fullName: string;
  phoneNumber: string;
  drivingContest: DriverDrivingContestProps;
  status: DriverStatus;
  statusText: keyof typeof DriverStatus;
  createdAt: number;
  updatedAt: number;
  isPinSet: boolean;
  licenseNumber?: string;
  dateOfBirth?: number | null;
  address?: string;
  photo?: Photo;
  photoId: string | null;
}

export type DrivingContestNavigationState = {
  page?: string;
  pageSize?: string;
  search?: string;
  orderBy?: string; // equal to DrivingContestOrderBy
};

export interface DrivingContestEventPeriod {
  start?: number;
  end?: number;
}

export interface DrivingContestEventQuery {
  contestEvent?: DrivingContestEventPeriod;
  registerEvent?: DrivingContestEventPeriod;
  assessmentEvent?: DrivingContestEventPeriod;
}
