// #region IMPORT
import urlcat from 'urlcat';
import { JOFilterOrderBy } from '../../../constant/JobOrder.constant';
import { EndpointTagTypes } from '../../../constant/Types.constant';
import {
  DeliveryActivity,
  DeliveryActivityType,
} from '../../../model/DeliveryActivity.model';
import {
  JobOrder,
  JobOrderEvent,
  JobOrderExpense,
  JobOrderExpenseStatus,
  JobOrderExpenseType,
  JobOrderInfo,
  JOStatus,
  TransitDelivery,
} from '../../../model/JobOrder.model';
import { toSnakeCase } from '../../../util/helper.util';
import { Builder, MD, QD } from '../../api.baseQuery';
import { CommonApiMetadata, CommonApiResponse } from '../../api.endpoint';
import { dashboardTagTypes } from '../dashboard/dashboard.endpoint';
import { organizationTagTypes } from '../organization/organization.endpoint';
import { shipperOrderTagTypes } from '../shipperOrder/shipperOrder.endpoint';
// #endregion

// #region INTERFACE
export type JobOrderUpdateParams = {
  id: string;
  joDate?: number | null;
  joNumber?: string | null;
  driverId?: string | null;
  vehicleId?: string | null;
  travelExpense?: number | null;
  sealNumber?: string | null;
  isUseAssignment?: boolean;
  deliveries?: TransitDelivery[];
};
export type JobOrderUpdateResponse = {
  id: string;
} & CommonApiResponse;

// #region API
export type GetJOApiRequest = {
  from?: number;
  to?: number;
  search?: string;
  orderBy?: JOFilterOrderBy;
  driverId?: string;
  vehicleId?: string;
  page?: number;
  pageSize?: number;
  'status[]'?: JOStatus;
};

export type GetJOApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    jobOrders: JobOrder[];
  };

export type GetJOCountApiRequest = {
  from?: number;
  to?: number;
  search?: string;
  driverId?: string;
  vehicleId?: string;
  'status[]'?: JOStatus;
};

export type GetJOCountApiResponse = CommonApiResponse & {
  jobOrders: {
    totalCount: number;
  };
};

export type PostJobOrderCreateParams = {
  joNumber?: string;
  joDate?: number; // unix
  driverId?: string;
  vehicleId?: string;
  deliveries?: TransitDelivery[];
  travelExpense?: number;
  isUseAssignment?: boolean;
  sealNumber?: string;
};
export type PostJobOrderCreateResponse = CommonApiResponse & {
  jobOrder: { id: string; number: string };
};

export type PostJobOrderCreateBulkParamsData = {
  joNumber: string;
  joDate: number;
  driverName: string;
  vehicleName: string;
  soNumbers: string[];
  travelExpense?: number;
};

export type PostJobOrderCreateBulkParams = {
  jobOrders: PostJobOrderCreateBulkParamsData[];
};

export type PostJobOrderCreateBulkResponseResultErrorData = {
  number?: string | null;
  soNumber?: string[] | null;
};
export type PostJobOrderCreateBulkResponseResultError = {
  data: PostJobOrderCreateBulkResponseResultErrorData;
  code: string;
};

export type PostJobOrderCreateBulkResponseResult = {
  totalInput: number;
  succeededCount: number;
  failedCount: number;
  errors: PostJobOrderCreateBulkResponseResultError[];
};

export type PostJobOrderCreateBulkResponse = CommonApiResponse & {
  result: PostJobOrderCreateBulkResponseResult;
};

export type GetJOInfoApiResponse = CommonApiResponse & {
  jobOrder: JobOrderInfo;
};

export type GetJOInfoStatusApiResponse = CommonApiResponse & {
  jobOrder: Pick<JobOrder, 'status' | 'statusText'>;
};

export type ExportJOApiRequest = Omit<GetJOApiRequest, 'status[]'> & {
  status?: JOStatus[];
};

export type ExportJOApiResponse = CommonApiResponse & {
  report: {
    fileUrl: string;
  };
};

export type CompleteShipperOrderParam = { joId: string; id: string };
export type CompleteShipperOrderResponse = CommonApiResponse;

export type CompleteJobOrderParam = { id: string };
export type CompleteJobOrderResponse = CommonApiResponse;

export type RejectJOExpenseRequest = {
  joId: string;
  id: string;
  rejectionNote?: string;
};

export type RejectJOExpenseResponse = CommonApiResponse;

export type ApproveJOExpensePayload = {
  joId: string;
  id: string;
};

type ApproveJOExpenseError = {
  code: string;
  data: {
    id: string;
    joId: string;
  };
};

export type ApproveJOExpenseRequest = {
  expenses: ApproveJOExpensePayload[];
};

export type ApproveJOExpenseResponse = {
  ok: boolean;
  result: {
    totalInput: number;
    succeededCount: number;
    failedCount: number;
    errors: ApproveJOExpenseError[];
  };
};

type GetJobOrderDeliveryActivitiesParam = {
  joId: string;
  joDeliveryId: string;
  type?: DeliveryActivityType[];
  search?: string;
  page?: number;
  pageSize?: number;
};
type GetJobOrderDeliveryActivitiesResponse = CommonApiResponse &
  CommonApiMetadata & { activities: DeliveryActivity[] };

type GetJobOrderDeliveryActivitiesCountParam =
  GetJobOrderDeliveryActivitiesParam;
type GetJobOrderDeliveryActivitiesCountResponse = CommonApiResponse &
  CommonApiMetadata & { activities: { totalCount: number } };

type CompleteJobOrderDeliveryActivityParam = {
  id: string;
  joId: string;
  joDeliveryId?: string;
};
export type CompleteJobOrderDeliveryActivityResponse = CommonApiResponse;

export type GetJOSpendingReportApiRequest = {
  page?: number;
  pageSize?: number;
  orderBy?: JOFilterOrderBy;
  'type[]'?: JobOrderExpenseType;
  'status[]'?: JobOrderExpenseStatus;
};

export type GetJOSpendingReportApiResponse = CommonApiResponse &
  CommonApiMetadata & {
    expenses: JobOrderExpense[];
  };

export type GetJOSpendingReportCountApiRequest = {
  joId: string;
  'type[]'?: JobOrderExpenseType;
  'status[]'?: JobOrderExpenseStatus;
};

export type GetJOSpendingReportCountApiResponse = CommonApiResponse & {
  expenses: {
    totalCount: number;
  };
};

export type GetJOSpendingReportStatusCountApiRequest = {
  joId: string;
};

export type GetJOSpendingReportStatusCountApiResponse = CommonApiResponse & {
  expenses: {
    total: string; // in number
    draft: string; // in number
    pending: string; // in number
    approved: string; // in number
    rejected: string; // in number
  };
};

export type JobOrdersExpensesInfoParams = {
  id: string;
};
export type JobOrdersExpensesInfoResponse = CommonApiResponse & {
  jobOrderExpense: JobOrderExpense;
};

export type DeleteJobOrderApiRequest = {
  id: string;
};
export type DeleteJobOrderApiResponse = CommonApiResponse;

export type GetJobOrdersEventListRequest = {
  joId: string;
};
export type GetJobOrdersEventListResponse = CommonApiResponse & {
  events: JobOrderEvent[];
};

export type GetJobOrdersInfoResponse = CommonApiResponse & {
  jobOrder: { number: string };
};

export type JobOrderEndpoint = {
  jobOrderUpdate: MD<JobOrderUpdateParams, JobOrderUpdateResponse>;
  jobOrderNumbersInfo: QD<void, GetJobOrdersInfoResponse>;
  getJobOrdersCount: QD<GetJOCountApiRequest | string, GetJOCountApiResponse>;
  getJobOrders: QD<GetJOApiRequest | string, GetJOApiResponse>;
  createJobOrders: MD<PostJobOrderCreateParams, PostJobOrderCreateResponse>;
  createJobOrdersBulk: MD<
    PostJobOrderCreateBulkParams,
    PostJobOrderCreateBulkResponse
  >;
  assignJobOrders: MD<{ id: string }, PostJobOrderCreateResponse>;
  getJobOrderInfo: QD<string, GetJOInfoApiResponse>;
  getJobOrderInfoStatus: QD<string, GetJOInfoStatusApiResponse>;
  forceCompleteJobOrders: MD<CompleteJobOrderParam, CommonApiResponse>;
  forceCompleteShipperOrders: MD<
    CompleteShipperOrderParam,
    CompleteShipperOrderResponse
  >;
  exportJobOrders: MD<ExportJOApiRequest, ExportJOApiResponse>;
  rejectJOExpense: MD<RejectJOExpenseRequest, RejectJOExpenseResponse>;
  approveJOExpense: MD<ApproveJOExpenseRequest, ApproveJOExpenseResponse>;
  getJobOrderDeliveryActivities: QD<
    GetJobOrderDeliveryActivitiesParam | string,
    GetJobOrderDeliveryActivitiesResponse
  >;
  getJobOrderDeliveryActivitiesCount: QD<
    GetJobOrderDeliveryActivitiesCountParam | string,
    GetJobOrderDeliveryActivitiesCountResponse
  >;
  completeJobOrderDeliveryActivity: MD<
    CompleteJobOrderDeliveryActivityParam,
    CompleteJobOrderDeliveryActivityResponse
  >;
  getJobOrderSpendingReportList: QD<
    GetJOSpendingReportApiRequest | string,
    GetJOSpendingReportApiResponse
  >;
  getJobOrderSpendingReportCount: QD<
    GetJOSpendingReportCountApiRequest | string,
    GetJOSpendingReportCountApiResponse
  >;
  getJobOrderSpendingReportStatusCount: QD<
    GetJOSpendingReportCountApiRequest | string,
    GetJOSpendingReportCountApiResponse
  >;
  getJobOrdersExpensesInfo: QD<
    JobOrdersExpensesInfoParams,
    JobOrdersExpensesInfoResponse
  >;
  deleteJobOrder: MD<DeleteJobOrderApiRequest, DeleteJobOrderApiResponse>;
  getJobOrderEventList: QD<
    GetJobOrdersEventListRequest,
    GetJobOrdersEventListResponse
  >;
};

// #endregion

// #region ENDPOINT\
export const jobOrderTagTypes: EndpointTagTypes<JobOrderEndpoint> = {
  getJobOrdersCount: '',
  getJobOrders: 'jobOrder',
  createJobOrders: '',
  createJobOrdersBulk: '',
  assignJobOrders: '',
  getJobOrderInfo: 'jobOrder.info',
  getJobOrderInfoStatus: '',
  forceCompleteJobOrders: '',
  forceCompleteShipperOrders: '',
  jobOrderUpdate: '',
  jobOrderNumbersInfo: 'jobOrder.numbers.info',
  exportJobOrders: '',
  getJobOrderDeliveryActivities: 'jobOrder.delivery.activities',
  getJobOrderDeliveryActivitiesCount: '',
  completeJobOrderDeliveryActivity: '',
  rejectJOExpense: '',
  approveJOExpense: '',
  getJobOrderSpendingReportList: 'jobOrder.expenses',
  getJobOrderSpendingReportCount: 'jobOrder.expenses.count',
  getJobOrderSpendingReportStatusCount: 'jobOrder.expenses.status.count',
  getJobOrdersExpensesInfo: 'jobOrder.spending.expenses',
  deleteJobOrder: '',
  getJobOrderEventList: 'jobOrder.event.list',
};

export const jobOrderEndpoint = (builder: Builder): JobOrderEndpoint => ({
  getJobOrdersCount: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/job-orders.count?${param}`
          : urlcat('/v1/job-orders.count', toSnakeCase(param)),
    }),
    providesTags: [jobOrderTagTypes.getJobOrders],
  }),
  jobOrderNumbersInfo: builder.query({
    query: () => ({
      url: '/v1/job-orders.numbers.info',
    }),
    providesTags: [jobOrderTagTypes.getJobOrders],
  }),

  getJobOrders: builder.query({
    query: (param) => ({
      url:
        typeof param === 'string'
          ? `/v1/job-orders.list?${param}`
          : urlcat('/v1/job-orders.list', toSnakeCase(param)),
    }),
    providesTags: (result) =>
      result
        ? [
            ...result.jobOrders.map(({ id }) => ({
              type: jobOrderTagTypes.getJobOrders,
              id,
            })),
            { type: jobOrderTagTypes.getJobOrders, id: 'LIST' },
            jobOrderTagTypes.getJobOrders,
          ]
        : [
            { type: jobOrderTagTypes.getJobOrders, id: 'LIST' },
            jobOrderTagTypes.getJobOrders,
          ],
  }),
  createJobOrders: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.create',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      jobOrderTagTypes.getJobOrders,
      organizationTagTypes.getOrganizationMe,
    ],
  }),
  createJobOrdersBulk: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.create.bulk',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      jobOrderTagTypes.getJobOrders,
      organizationTagTypes.getOrganizationMe,
    ],
  }),
  assignJobOrders: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.assign',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      jobOrderTagTypes.getJobOrders,
      dashboardTagTypes.getOrganizationSummary,
      dashboardTagTypes.getSummaryTotal,
    ],
  }),
  getJobOrderInfo: builder.query({
    query: (joId) => ({
      url: urlcat('/v1/job-orders.info', { id: joId }),
    }),
    providesTags: (_r, _e, params) => [
      { type: jobOrderTagTypes.getJobOrderInfo, id: 'LIST' },
      { type: jobOrderTagTypes.getJobOrderInfo, id: params },
      jobOrderTagTypes.getJobOrderInfo,
    ],
  }),
  getJobOrderInfoStatus: builder.query({
    query: (joId) => ({
      url: urlcat('/v1/job-orders.info.status', { id: joId }),
    }),
  }),
  forceCompleteJobOrders: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.complete',
      body,
      method: 'post',
    }),
    // Invalidates all queries that subscribe to this JobOrder `id` only.
    invalidatesTags: (_result, _error, body) => [
      { type: jobOrderTagTypes.getJobOrders, id: body.id },
      jobOrderTagTypes.getJobOrderEventList,
      dashboardTagTypes.getOrganizationSummary,
      dashboardTagTypes.getSummaryTotal,
    ],
  }),
  forceCompleteShipperOrders: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.deliveries.complete',
      body,
      method: 'post',
    }),
    invalidatesTags: (_result, _error, body) => [
      { type: jobOrderTagTypes.getJobOrders, id: body.joId },
      { type: shipperOrderTagTypes.getShipperOrders, id: body.id },
      { type: jobOrderTagTypes.getJobOrderInfo, id: body.id },
      jobOrderTagTypes.getJobOrders,
      jobOrderTagTypes.getJobOrderInfo,
    ],
  }),
  jobOrderUpdate: builder.mutation({
    query: (body) => ({ url: '/v1/job-orders.update', body, method: 'post' }),
    invalidatesTags: [jobOrderTagTypes.getJobOrders],
  }),
  exportJobOrders: builder.mutation({
    query: (body) => ({ url: '/v1/job-orders.export', body, method: 'post' }),
  }),
  rejectJOExpense: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.expenses.reject',
      body,
      method: 'post',
    }),
    invalidatesTags: () => [
      { type: jobOrderTagTypes.getJobOrderInfo, id: 'LIST' },
      { type: jobOrderTagTypes.getJobOrdersExpensesInfo, id: 'LIST' },
      { type: jobOrderTagTypes.getJobOrderSpendingReportList, id: 'LIST' },
    ],
  }),
  approveJOExpense: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.expenses.approves',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      { type: jobOrderTagTypes.getJobOrderInfo, id: 'LIST' },
      { type: jobOrderTagTypes.getJobOrdersExpensesInfo, id: 'LIST' },
      { type: jobOrderTagTypes.getJobOrderSpendingReportList, id: 'LIST' },
    ],
  }),
  getJobOrderDeliveryActivities: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/job-orders.deliveries.activities.in-process.list?${param}`
        : urlcat(
            '/v1/job-orders.deliveries.activities.in-process.list',
            toSnakeCase(param),
          ),
    providesTags: [jobOrderTagTypes.getJobOrderDeliveryActivities],
  }),
  getJobOrderDeliveryActivitiesCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/job-orders.deliveries.activities.in-process.count?${param}`
        : urlcat(
            '/v1/job-orders.deliveries.activities.in-process.count',
            toSnakeCase(param),
          ),
    providesTags: [jobOrderTagTypes.getJobOrderDeliveryActivitiesCount],
  }),
  completeJobOrderDeliveryActivity: builder.mutation({
    query: (body) => ({
      url: '/v1/job-orders.deliveries.activities.complete',
      body,
      method: 'post',
    }),
    invalidatesTags: [
      jobOrderTagTypes.getJobOrderDeliveryActivities,
      jobOrderTagTypes.getJobOrderInfo,
    ],
  }),
  getJobOrdersExpensesInfo: builder.query({
    query: (params) => ({
      url: urlcat('/v1/job-orders.expenses.info', params),
    }),
    providesTags: (_r, _e, params) => [
      { type: jobOrderTagTypes.getJobOrdersExpensesInfo, id: 'LIST' },
      { type: jobOrderTagTypes.getJobOrdersExpensesInfo, id: params.id },
    ],
  }),
  getJobOrderSpendingReportList: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/job-orders.expenses.list?${param}`
        : urlcat('/v1/job-orders.expenses.list', toSnakeCase(param)),
    providesTags: () => [
      { type: jobOrderTagTypes.getJobOrderSpendingReportList, id: 'LIST' },
    ],
  }),
  getJobOrderSpendingReportCount: builder.query({
    query: (param) =>
      typeof param === 'string'
        ? `/v1/job-orders.expenses.count?${param}`
        : urlcat('/v1/job-orders.expenses.count', toSnakeCase(param)),
    providesTags: () => [
      { type: jobOrderTagTypes.getJobOrderSpendingReportCount, id: 'LIST' },
    ],
  }),
  getJobOrderSpendingReportStatusCount: builder.query({
    query: (param) =>
      urlcat('/v1/job-orders.expenses.count.status', toSnakeCase(param)),
    providesTags: () => [
      {
        type: jobOrderTagTypes.getJobOrderSpendingReportStatusCount,
        id: 'LIST',
      },
    ],
  }),
  deleteJobOrder: builder.mutation({
    query: (body) => ({
      body,
      url: '/v1/job-orders.delete',
      method: 'post',
    }),
    invalidatesTags: [
      jobOrderTagTypes.getJobOrders,
      jobOrderTagTypes.getJobOrderInfo,
    ],
  }),
  getJobOrderEventList: builder.query({
    query: (param) => urlcat('/v1/job-orders.events.list', toSnakeCase(param)),
    providesTags: () => [
      { type: jobOrderTagTypes.getJobOrderEventList, id: 'LIST' },
    ],
  }),
});
// #endregion
