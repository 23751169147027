import React from 'react';
import tw, { styled } from 'twin.macro';
import { CustomMapTypeItem } from '../../../model/Map.model';
import { Image } from '../../atom';
import { ExtraStyle } from '../../Type.component';

const Container = tw.div`flex flex-col items-center`;
const MapTypeItem = styled.div((props: { isSelected?: boolean }) => [
  tw`w-16 h-16 relative flex cursor-pointer mr-1 hover:(border-2 border-orange rounded-md)`,
  props.isSelected && tw`border-2 border-orange rounded-md`,
]);

const MapTypeLabel = styled.div((props: { isSelected?: boolean }) => [
  tw`font-bold text-[10px] tracking-[0.2px] text-black leading-3 text-center mt-2`,
  props.isSelected && tw`text-orange`,
]);

interface Props {
  item: CustomMapTypeItem;
  isSelected?: boolean;
  containerStyle?: ExtraStyle;
}

export default function MapTypeControllerMobileItem({
  item,
  isSelected,
  containerStyle,
}: Props) {
  return (
    <Container>
      <MapTypeItem
        css={containerStyle}
        isSelected={isSelected}
        onClick={item.action}
      >
        <Image tw="rounded" src={item.img} />
      </MapTypeItem>
      <MapTypeLabel isSelected={isSelected}>{item.label}</MapTypeLabel>
    </Container>
  );
}
