import React from 'react';
import tw, { styled } from 'twin.macro';
import { Check } from '../Icon/Icon.atom';

// #region PROPS
export type CheckboxProps = React.ComponentProps<'input'> & {
  indeterminate?: boolean;
};

type CheckboxButtonProps = {
  checked?: boolean;
  disabled?: boolean;
  indeterminate?: boolean;
};
// #endregion

// #region STYLED COMPONENTS
const HiddenInput = tw.input`w-9 h-9 absolute m-0 inset-0 opacity-0 cursor-pointer`;

const Control = styled.span(({ disabled }: { disabled?: boolean }) => [
  tw`relative p-[18px] -m-2.5 flex-none duration-200`,
  !disabled &&
    tw`after:(content-[' '] absolute inset-0 rounded-full bg-orange-four bg-opacity-0 hover:bg-opacity-40 group-hover:bg-opacity-40 focus:bg-opacity-100 group-focus:bg-opacity-100)`,
]);

const CheckButton = styled.div(
  ({ checked, disabled, indeterminate }: CheckboxButtonProps) => [
    tw`w-[18px] h-[18px] absolute inset-2 m-px rounded-sm border-2 border-solid border-grey-four bg-white duration-200 peer-checked:(border-orange bg-orange) peer-disabled:(border-grey-four bg-grey-four) peer-disabled:peer-checked:(border-grey-four bg-transparent) peer-focus:border-orange`,
    (checked || indeterminate) && tw`border-orange bg-orange`,
    disabled && tw`border-grey-four bg-grey-four`,
    checked && disabled && tw`border-grey-four bg-transparent`,
  ],
);

const CheckMark = styled(Check)(
  ({ checked, disabled }: CheckboxButtonProps) => [
    tw`w-[18px] h-[18px] absolute inset-2 m-px duration-200 text-transparent peer-checked:text-white`,
    checked && tw`bg-orange stroke-white`,
    disabled && tw`bg-grey-four`,
  ],
);

const CheckStrip = styled.div(({ checked, disabled }: CheckboxButtonProps) => [
  tw`w-2.5 h-0.5 absolute inset-y-4 inset-x-3 m-px duration-200 text-transparent peer-checked:text-white`,
  checked && tw`bg-white`,
]);
// #endregion

export default function Checkbox(props: CheckboxProps) {
  const { checked, disabled, indeterminate } = props;
  const isChecked = checked ?? false;
  const isDisabled = disabled ?? false;
  const isIndeterminate = indeterminate ?? false;

  return (
    <Control disabled={disabled}>
      <HiddenInput type="checkbox" className="peer" {...props} />
      <CheckButton
        checked={isChecked}
        disabled={isDisabled}
        indeterminate={isIndeterminate}
      />
      <CheckMark checked={isChecked} disabled={isDisabled} />
      <CheckStrip checked={isIndeterminate} disabled={isDisabled} />
    </Control>
  );
}
