import useJobOrderUploadModal from '../../hook/jobOrder/useJobOrderUploadModal.hook';
import useTranslator from '../../hook/useTranslator.hook';
import useJOCreateBulkLogic from './hooks/useJOCreateBulkLogic.hook';
import useJOCreateBulkTable from './hooks/useJOCreateBulkTable.hook';
import useJOSOListModal from './hooks/useJOSOListModal.hook';

export default function useJOCreateBulkViewModel() {
  const translator = useTranslator();

  const soListModal = useJOSOListModal();
  const uploadModal = useJobOrderUploadModal();
  const bulkLogic = useJOCreateBulkLogic();
  const bulkTable = useJOCreateBulkTable({ bulkLogic, soListModal });

  return {
    translator,
    uploadModal,
    soListModal,
    bulkLogic,
    bulkTable,
  };
}
