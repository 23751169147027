import fromUnixTime from 'date-fns/fromUnixTime';
import React, { CSSProperties, useMemo } from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import { RedirectUrlTarget } from '../../../constant';
import { JobOrderInfoDelivery, JOStatus } from '../../../model/JobOrder.model';
import { getDateFormat } from '../../../util/date.util';
import { getShortLabel } from '../../../util/formatter.util';
import { getJODetailActivities } from '../../../util/jobOrder.util';
import { shipperOrderDetailRoute } from '../../../view/ShipperOrderDetail/ShipperOrderDetail.route';
import { Icon, LoadingIndicator, Text } from '../../atom';
import { ExtraStyle } from '../../Type.component';
import ShipperOrderStatus from '../ShipperOrderStatus/ShipperOrderStatus.molecule';

// #region INTERFACES
type Props = {
  joStatus: JOStatus;
  delivery: JobOrderInfoDelivery;
  isSelected: boolean;
  soStatusLabel: string;
  containerStyle?: ExtraStyle | CSSProperties;
  onSelectDeliveryItem(): void;
  forceLoading?: boolean;
};
// #endregion

// #region STYLED
const SOListItemContainer = styled.section<{ isSelected: boolean }>(
  ({ isSelected }) => [
    tw`flex flex-col gap-2 px-4 py-3 rounded-md cursor-pointer animate-slide-in-left mb-2.5`,
    isSelected ? tw`bg-orange-hover` : tw`border-2 border-beige-lines`,
  ],
);
const SOListItemNumberContainer = tw.div`grid grid-cols-3 grid-rows-2`;
const SOListItemStatusContainer = tw.div`flex flex-row justify-between`;
const TimelineContainer = tw.ul`relative w-full list-style[none]`;
const TimelineLocation = tw.li`relative pl-2 ml-2 pb-2 border-l border-dashed border-l-assigned-dark list-style[none] last-of-type:border-0 last-of-type:pb-0`;
const TimelineIconContainer = styled.div<{
  isSelected: boolean;
  isPickup: boolean;
}>(({ isSelected, isPickup }) => [
  tw`absolute top-0 -left-3 bg-white text-status-alert`,
  isSelected && tw`bg-orange-hover`,
  isPickup && tw`text-black`,
]);
const TimelineContentContainer = tw.div`ml-4`;
// #endregion

export default function JODetailSOListItem({
  joStatus,
  delivery,
  isSelected,
  soStatusLabel,
  containerStyle,
  onSelectDeliveryItem,
  forceLoading,
}: Props) {
  const { pickUpActivities, dropOffActivities } = getJODetailActivities(
    delivery.activities,
  );
  const renderSOStatus = useMemo(() => {
    let whatToRender =
      joStatus !== JOStatus.DELETED ? (
        <ShipperOrderStatus
          deliveryStatus={delivery.status}
          statusLabel={soStatusLabel}
        />
      ) : null;
    if (forceLoading) whatToRender = <LoadingIndicator size="small" />;

    return whatToRender;
  }, [delivery.status, forceLoading, joStatus, soStatusLabel]);

  return (
    <SOListItemContainer
      isSelected={isSelected}
      onClick={(e) => {
        e.stopPropagation();
        onSelectDeliveryItem();
      }}
      css={containerStyle}
    >
      <SOListItemNumberContainer>
        <Text.LabelPrimaryBold tw="text-base row-span-2 col-span-2 truncate">
          <Link
            to={`${shipperOrderDetailRoute.path.replace(
              ':id',
              delivery.shipperOrder.id,
            )}?breadcrumb=${getShortLabel(delivery.shipperOrder.number)}`}
            target={RedirectUrlTarget.BLANK}
          >
            {delivery.shipperOrder.number}
          </Link>
        </Text.LabelPrimaryBold>
        <Text.BodyThree tw="row-span-2 text-right">
          {getDateFormat(fromUnixTime(delivery.shipperOrder.date))}
        </Text.BodyThree>
      </SOListItemNumberContainer>
      <SOListItemStatusContainer>
        <Text.HeadingFive tw="leading-[20px] font-weight[500] truncate">
          {delivery.shipperOrder.shipper.name}
        </Text.HeadingFive>

        {renderSOStatus}
      </SOListItemStatusContainer>

      <TimelineContainer>
        {pickUpActivities.length > 0 && (
          <TimelineLocation>
            <TimelineIconContainer isSelected={isSelected} isPickup>
              <Icon.NavMaps />
            </TimelineIconContainer>

            <TimelineContentContainer>
              <Text.Paragraph tw="font-bold text-[12px] truncate">
                {pickUpActivities.length > 1 && pickUpActivities.length} Pickup
                Location
              </Text.Paragraph>

              {isSelected &&
                pickUpActivities.map((pickUp) => (
                  <Text.Paragraph key={pickUp.id} tw="text-[12px] truncate">
                    {pickUp.location?.name || pickUp.soActivity?.location?.name}
                  </Text.Paragraph>
                ))}

              {!isSelected && pickUpActivities.length === 1 && (
                <Text.Paragraph tw="text-[12px] truncate">
                  {pickUpActivities[0].location?.name ||
                    pickUpActivities[0].soActivity?.location?.name}
                </Text.Paragraph>
              )}
            </TimelineContentContainer>
          </TimelineLocation>
        )}

        {dropOffActivities.length > 0 && (
          <TimelineLocation>
            <TimelineIconContainer isSelected={isSelected} isPickup={false}>
              <Icon.NavMaps />
            </TimelineIconContainer>

            <TimelineContentContainer>
              <Text.Paragraph tw="font-bold text-[12px] truncate">
                {dropOffActivities.length > 1 && dropOffActivities.length}{' '}
                Drop-off Location
              </Text.Paragraph>

              {isSelected &&
                dropOffActivities.map((dropOff) => (
                  <Text.Paragraph key={dropOff.id} tw="text-[12px] truncate">
                    {dropOff.location?.name ||
                      dropOff.soActivity?.location?.name}
                  </Text.Paragraph>
                ))}

              {!isSelected && dropOffActivities.length === 1 && (
                <Text.Paragraph tw="text-[12px] truncate">
                  {dropOffActivities[0].location?.name ||
                    dropOffActivities[0].soActivity?.location?.name}
                </Text.Paragraph>
              )}
            </TimelineContentContainer>
          </TimelineLocation>
        )}
      </TimelineContainer>
    </SOListItemContainer>
  );
}
