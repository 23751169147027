import React from 'react';
import tw, { theme } from 'twin.macro';
import envConfig from '../../../config/env/env.config';
import useScreenDetection from '../../../hook/useScreenDetection/useScreenDetection.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { formatText } from '../../../util/formatter.util';
import { helpCenterDriverContestRegisterRoute } from '../../../view/HelpCenter/sections/DriverContest/HelpCenterDriverContestRegister/HelpCenterDriverContestRegister.route';
import { Icon, Text } from '../../atom';

// #region STYLED
const Header = tw.div`py-2.5 mb-8 w-full flex justify-center items-center gap-2.5 bg-black`;
const HeaderTitle = tw(Text.HeadingFour)`leading-[26px] text-white`;
const CTALink = tw.a`text-orange hover:underline`;

const HeaderTitleMobile = tw(
  Text.HeadingFive,
)`text-white whitespace-pre-wrap leading-[24px] `;
const HeaderMobile = tw.div`w-full flex gap-2 p-2.5 bg-black`;
// #endregion

// #region INTERFACES
type Props = {
  showBanner: boolean;
  year?: number | string;
};
// #endregion

export default function DrivingContestBanner({
  showBanner,
  year = ' ',
}: Props) {
  const translator = useTranslator();
  const { isMobile } = useScreenDetection();
  const baseUrl = `${envConfig.ktbJOServiceUrl}${helpCenterDriverContestRegisterRoute.path}`;
  if (!showBanner) return null;
  if (isMobile)
    return (
      <HeaderMobile>
        <Icon.DrivingContest
          height={24}
          width={24}
          fillPath={theme`colors.white`}
          isExpanded
        />
        <HeaderTitleMobile>
          {formatText(
            translator.translate(
              'Driver Contest %s has been started! Access this page using your desktop to find out more.',
            ),
            year,
          )}
        </HeaderTitleMobile>
      </HeaderMobile>
    );

  return (
    <Header>
      <Icon.DrivingContest fillPath={theme`colors.white`} isExpanded />

      <HeaderTitle>
        {formatText(
          translator.translate('Driver Contest %s has been started!'),
          year,
        )}{' '}
        <CTALink href={baseUrl} target="_blank" rel="noreferrer">
          {translator.translate('Click here')}
        </CTALink>{' '}
        {translator.translate('for more information')}.
      </HeaderTitle>
    </Header>
  );
}
