import React from 'react';
import { Link } from 'react-router-dom';
import tw, { styled } from 'twin.macro';
import useMobileBottomBar, {
  MobileBottomBarItemProps,
} from '../../../hook/useMobileBottomBar/useMobileBottomBar.hook';
import { Text } from '../../atom';
import MobileBottomBarBottomSheet from './MobileBottomBarBottomSheet.organism';
import MobileBottomBarReportMenuList from './MobileBottomBarReportMenuList.organism';

// #region STYLED
const BottomBar = tw.section`w-full flex items-center justify-around border-t border-grey-four py-2 bg-white`;
const Linking = tw(Link)`flex flex-col w-[111px] space-y-1 items-center`;
const ExternalLinking = tw.a`flex flex-col w-[111px] space-y-1 items-center`;
const NavigationText = styled(Text.MobileTwelve)(
  ({ isActive }: { isActive?: boolean }) => [
    tw`font-bold tracking-[-0.4px]`,
    isActive && tw`text-orange`,
  ],
);
const ButtonNav = tw.div`flex flex-col items-center w-[111px] gap-1`;
// #endregion

function MenuItem({
  url,
  icon,
  title,
  isActive,
  isExternalLink,
  handleClick,
}: MobileBottomBarItemProps) {
  if (isExternalLink)
    return (
      <ExternalLinking href={url} target="_blank">
        {icon}
        <NavigationText isActive={isActive}>{title}</NavigationText>
      </ExternalLinking>
    );

  if (!url) {
    return (
      <ButtonNav onClick={handleClick}>
        {icon}
        <NavigationText isActive={isActive}>{title}</NavigationText>
      </ButtonNav>
    );
  }
  return (
    <Linking to={url}>
      {icon}
      <NavigationText isActive={isActive}>{title}</NavigationText>
    </Linking>
  );
}

export default function MobileBottomBar() {
  const {
    menuList,
    isDashboardRoute,
    bottomSheetMenuList,
    reportDialogMenuList,
    isDialogReportVisible,
    isBottomSheetVisible,
    handleDismissReportDialog,
    handleDismissBottomSheet,
  } = useMobileBottomBar();
  if (!isDashboardRoute) return null;
  return (
    <BottomBar>
      {menuList.map((item) => (
        <MenuItem
          key={item.title}
          icon={item.icon}
          title={item.title}
          url={item.url}
          isActive={item.isActive}
          handleClick={item.handleClick}
          isExternalLink={item.isExternalLink}
        />
      ))}
      <MobileBottomBarReportMenuList
        isVisible={isDialogReportVisible}
        menuItems={reportDialogMenuList}
        handleOverlayClick={handleDismissReportDialog}
      />
      <MobileBottomBarBottomSheet
        isVisible={isBottomSheetVisible}
        menuList={bottomSheetMenuList}
        handleDismiss={handleDismissBottomSheet}
      />
    </BottomBar>
  );
}
