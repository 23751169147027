import React, { cloneElement, isValidElement } from 'react';
import tw, { styled } from 'twin.macro';

export type ListItemProps = React.ComponentProps<'li'> & {
  label: string;
  selected?: boolean;
  left?: React.ReactNode;
  right?: React.ReactNode;
};

const Container = styled.li(
  (props: { selected?: boolean; small?: boolean; disabled?: boolean }) => [
    tw`py-3 px-6 bg-transparent flex flex-row items-center`,
    tw`hover:bg-orange-hover`,
    props.selected && tw`bg-orange-hover`,
    props.small && tw`py-1.5 px-5`,
    props.disabled && tw`text-grey-four hover:bg-transparent`,
  ],
);
const IconContainer = tw.span`w-5 h-5 mr-5 flex flex-row items-center justify-center`;
const Label = styled.span(
  ({ small, bold }: { small?: boolean; bold?: boolean }) => [
    tw`flex-1 font-sans leading-5 py-px select-none`,
    small && tw`text-[14px] py-0.5`,
    bold && tw`font-semibold`,
  ],
);

export function Regular({
  label,
  left,
  right,
  selected,
  ...props
}: ListItemProps) {
  return (
    <Container selected={selected} {...props}>
      {isValidElement(left) && (
        <IconContainer>
          {cloneElement(left, { width: 18, height: 18 })}
        </IconContainer>
      )}
      <Label>{label}</Label>
      {right}
    </Container>
  );
}

export function Small({
  label,
  left,
  right,
  selected,
  ...props
}: ListItemProps) {
  return (
    <Container small selected={selected} {...props}>
      {isValidElement(left) && (
        <IconContainer>
          {cloneElement(left, { width: 18, height: 18 })}
        </IconContainer>
      )}
      <Label small>{label}</Label>
      {right}
    </Container>
  );
}
