import { nanoid } from '@reduxjs/toolkit';
import fromUnixTime from 'date-fns/fromUnixTime';
import React, { useMemo, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import useTranslator, { UseTranslator } from '../../../hook/useTranslator.hook';
import { Photo } from '../../../model/Common.model';
import {
  SOIndexedTimeline,
  SOTracking,
  SOTrackingLocation,
  TimelineDataPhoto,
} from '../../../model/ShipperOrder.model';
import { getFullDateTimeFormat } from '../../../util/date.util';
import {
  getSOTimelineLocationName,
  getSOTrackingPOAPhotos,
  getTimelinePhoto,
  getTrackingTimelineLabel,
  remapSOTimelines,
  soTimelinePhotoActivitySelector,
} from '../../../util/tracking/trackingTimeline.util';
import { Button, Icon, Text } from '../../atom';
import { TimelineContentPhoto } from '../TimelineContentItem/TimelineContentItem.type';

const Divider = tw.div`my-1 flex`;
const Section = tw.div`bg-white p-4 flex flex-col gap-2`;

const TimelineTripHistoryItemContainer = styled.div(
  ({ isLastItem }: { isLastItem?: boolean }) => [
    tw`grid grid-cols-[32px minmax(128px,100%)]`,
    !isLastItem && tw`mb-3`,
  ],
);

export function TrackingHeaderItem({
  title,
  values,
  showFootprint,
  isLastItem,
  isPickup,
  forceLang,
  handleShowFullContent = () => {},
}: {
  title: string;
  values: string[];
  forceLang?: SupportedLanguage;
  showFootprint?: boolean;
  isLastItem?: boolean;
  isPickup?: boolean;
  handleShowFullContent?: () => void;
}) {
  const translator = useTranslator();
  const [showMore, setShowMore] = useState(false);

  const buttonText = useMemo(() => {
    const text = showMore ? 'Show Less' : 'Show More';
    return `${translator.translate(text, forceLang)}...`;
  }, [forceLang, showMore, translator]);
  const slicedLocations = useMemo(() => {
    if (values.length > 3 && showMore) return values;
    if (values.length > 3) return values.slice(0, 2);
    return values;
  }, [showMore, values]);

  const handleClickShowMore = () => {
    handleShowFullContent();
    setShowMore((v) => !v);
  };

  return (
    <div tw="grid grid-cols-[32px minmax(128px,100%)]">
      <div tw="w-4 justify-center flex pt-1 mr-2.5 relative">
        <div>
          <Icon.MapMarker fill={!isPickup ? theme`colors.deleted.dark` : ''} />
        </div>
        {showFootprint && !isLastItem && (
          <div tw="border-l absolute top-[18px] left-[7.5px] h-full border-dashed border-grey-four" />
        )}
      </div>
      <div tw="flex flex-col">
        <div tw="flex items-center">
          <Text.BodyTwelve>{`${
            values.length > 1 ? `${values.length} ` : ''
          }${title}`}</Text.BodyTwelve>
        </div>
        <div tw="flex items-start w-full flex-col overflow-hidden">
          {slicedLocations.map((v) => (
            <Text.HeadingFive
              key={`location-${nanoid()}`}
              tw="whitespace-nowrap overflow-hidden overflow-ellipsis w-full"
            >
              {v}
            </Text.HeadingFive>
          ))}
          {values.length > 3 && (
            <Button.Text tw="text-xs" onClick={handleClickShowMore}>
              {buttonText}
            </Button.Text>
          )}
        </div>
      </div>
    </div>
  );
}

function TimelineTripHistoryItem({
  title,
  value,
  showFootprint,
  isLastItem,
  isFirstItem,
  data,
  onImageClick,
}: {
  title: string;
  value: string;
  showFootprint?: boolean;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  data?: TimelineDataPhoto;
  onImageClick?: (
    photos: Photo[],
    clickedIndex: number,
    customTitle?: string[],
  ) => void;
}) {
  const photos = useMemo((): TimelineContentPhoto[] => {
    if (!data?.photos?.length) return [];
    return getSOTrackingPOAPhotos(data);
  }, [data]);
  return (
    <TimelineTripHistoryItemContainer isLastItem={isLastItem}>
      <div tw="w-4 justify-center flex pt-1 mr-2.5 relative">
        <div>
          <div
            tw="w-3 h-3 rounded-full bg-grey-three relative"
            css={[isFirstItem && tw`bg-delivered-dark w-4 h-4 z-10`]}
          />
        </div>
        {showFootprint && !isLastItem && (
          <div tw="border-l absolute top-[18px] left-[7.5px] h-full border-dashed border-grey-four z-0" />
        )}
      </div>
      <div tw="flex flex-col">
        <div tw="flex items-center overflow-wrap[anywhere]">
          <Text.HeadingFive css={[!isFirstItem && tw`text-grey-three`]}>
            {title}
          </Text.HeadingFive>
        </div>
        <div tw="flex items-center w-full overflow-wrap[anywhere]">
          <Text.BodyTwelve
            tw="whitespace-nowrap overflow-hidden overflow-ellipsis"
            css={[!isFirstItem && tw`text-grey-three`]}
          >
            {value}
          </Text.BodyTwelve>
        </div>
        {!!data && !!onImageClick && (
          <div tw="grid gap-2 grid-cols-[repeat(3, minmax(92px,1fr))] grid-template-rows[repeat(auto-fit, minmax(100px,1fr))] place-items-center ">
            {photos.map(({ id, url }, photoIndex) => (
              <button
                type="button"
                onClick={() => {
                  onImageClick(
                    photos.map((photo) => ({
                      accessUrl: photo.url,
                      createdAt: data.createdAt || 0,
                      id,
                      // photo does not contains props down below but it is required to fill these properties based on interface
                      fileName: '',
                      mimeType: 'jpeg',
                      size: 0,
                    })),
                    photoIndex,
                    photos.map(soTimelinePhotoActivitySelector),
                  );
                }}
                key={id}
                tw="flex justify-center rounded overflow-hidden cursor-pointer bg-black max-w-[124px] max-h-[124px]"
                css={[data && photoIndex >= photos.length - 1 && tw`mr-0`]}
              >
                <img
                  src={url}
                  alt={url}
                  tw="object-cover aspect-ratio[1]  rounded-md w-full"
                />
              </button>
            ))}
          </div>
        )}
      </div>
    </TimelineTripHistoryItemContainer>
  );
}

function TimelineHeader({
  translator,
  pickupLocation,
  dropoffLocation,
  forceLang,
  handleShowFullContent,
}: {
  translator: UseTranslator;
  pickupLocation: SOTrackingLocation[];
  dropoffLocation: SOTrackingLocation[];
  forceLang?: SupportedLanguage;
  handleShowFullContent: () => void;
}) {
  return (
    <>
      {!!pickupLocation.length && (
        <>
          <TrackingHeaderItem
            handleShowFullContent={handleShowFullContent}
            forceLang={forceLang}
            title={translator.translate('Pickup Location', forceLang)}
            values={pickupLocation.map((pl) => pl.name)}
            showFootprint={!!dropoffLocation.length}
            isLastItem={!dropoffLocation.length}
            isPickup
          />
          <Divider />
        </>
      )}
      {dropoffLocation.length > 0 && (
        <>
          <TrackingHeaderItem
            handleShowFullContent={handleShowFullContent}
            forceLang={forceLang}
            title={translator.translate('Dropoff Location', forceLang)}
            values={dropoffLocation.map((pl) => pl.name)}
            isLastItem={!pickupLocation.length}
          />
          <Divider />
        </>
      )}
    </>
  );
}

function TimelineTripHistoryContent({
  translator,
  forceLang,
  timeline,
  isLastItem,
  isFirstItem,
  onImageClick,
}: {
  translator: UseTranslator;
  forceLang?: SupportedLanguage;
  timeline: SOIndexedTimeline;
  isLastItem: boolean;
  isFirstItem: boolean;
  onImageClick?: (
    photos: Photo[],
    clickedIndex: number,
    customTitle?: string[],
  ) => void;
}) {
  const location = getSOTimelineLocationName(timeline);
  const title = getTrackingTimelineLabel(
    { translate: translator.translate, type: timeline.type, forceLang },
    ...(location ? [location] : []),
  );
  const timelinePhoto = getTimelinePhoto(timeline);

  return (
    <TimelineTripHistoryItem
      title={title}
      data={timelinePhoto}
      isLastItem={isLastItem}
      showFootprint={!isLastItem}
      isFirstItem={isFirstItem}
      value={getFullDateTimeFormat(fromUnixTime(timeline.time), true)}
      onImageClick={onImageClick}
    />
  );
}

function TrackingGoodsMobile({
  translator,
  data,
  forceLang,
  showTripHistory,
  onImageClick,
  handleShowFullContent,
}: {
  translator: UseTranslator;
  data: SOTracking;
  forceLang?: SupportedLanguage;
  showTripHistory?: boolean;
  onImageClick?: (
    photos: Photo[],
    clickedIndex: number,
    customTitle?: string[],
  ) => void;
  handleShowFullContent: () => void;
}) {
  const timelines = useMemo(
    () => remapSOTimelines(data.timelines),
    [data.timelines],
  );
  const lastTimeline = timelines[0];
  const hasLocation = useMemo(
    () => data.pickupLocation.length + data.dropoffLocation.length > 0,
    [data.dropoffLocation.length, data.pickupLocation.length],
  );
  if (!lastTimeline) return null;

  if (showTripHistory)
    return (
      <div tw="bg-white p-4">
        {timelines.map((timeline, idx, arr) => (
          <TimelineTripHistoryContent
            key={nanoid()}
            translator={translator}
            timeline={timeline}
            forceLang={forceLang}
            isLastItem={idx === arr.length - 1}
            isFirstItem={idx === 0}
            onImageClick={onImageClick}
          />
        ))}
      </div>
    );

  if (!hasLocation) return null;

  return (
    <Section>
      <div tw="flex flex-col">
        <Text.HeadingFour tw="pb-2">
          {translator.translate('Destination', forceLang)}
        </Text.HeadingFour>

        {hasLocation && (
          <TimelineHeader
            handleShowFullContent={handleShowFullContent}
            dropoffLocation={data.dropoffLocation}
            pickupLocation={data.pickupLocation}
            translator={translator}
            forceLang={forceLang}
          />
        )}
      </div>
    </Section>
  );
}

export default TrackingGoodsMobile;
