import React from 'react';
import tw from 'twin.macro';
import { Modal } from '..';
import useBlockRoute from '../../../hook/useBlockRoute/useBlockRoute.hook';
import useTranslator from '../../../hook/useTranslator.hook';
import { Button, Text } from '../../atom';
import { ModalComposeRootProps } from '../Modal/Modal.molecule';

const ModalSection = tw.section`flex flex-col`;
const ModalTitleContainer = tw.div`p-5 w-full flex flex-col space-y-2 border-b border-b-beige-lines`;
const ModalTitle = tw(Text.HeadingThree)`text-[20px]`;
const ModalSubtitle = tw(Text.BodySixteen)`text-grey-two`;
const ModalFooter = tw.footer`p-5 w-full flex justify-between`;

type Props = ModalComposeRootProps & {
  hasUnsavedChanges: boolean;
  onCancelActionClick?: () => void;
  titleLabel?: string;
  subtitleLabel?: string;
  leaveLabel?: string;
  stayLabel?: string;
};

export default function BeforeLeaveAlertModal({
  hasUnsavedChanges,
  containerStyle = [tw`p-0 w-[480px]`],
  onOverlayClick,
  onCancelActionClick = () => {},
  titleLabel = 'Are you sure to leave this process?',
  subtitleLabel = "This process won't be saved, and will be lost unless you continue.",
  leaveLabel = 'Leave',
  stayLabel = 'Continue',
}: Props) {
  const translator = useTranslator();
  const { displayAlert, leavePage, stayOnPage } = useBlockRoute({
    hasUnsavedChanges,
    onCancelActionClick,
  });

  if (!displayAlert) return null;

  return (
    <Modal.ComposeRoot
      containerStyle={containerStyle}
      onOverlayClick={onOverlayClick}
    >
      <ModalSection>
        <ModalTitleContainer>
          <ModalTitle>{translator.translate(titleLabel)}</ModalTitle>
          <ModalSubtitle>{translator.translate(subtitleLabel)}</ModalSubtitle>
        </ModalTitleContainer>

        <ModalFooter>
          <Button.Outlined onClick={leavePage}>
            {translator.translate(leaveLabel)}
          </Button.Outlined>
          <Button.Solid onClick={stayOnPage}>
            {translator.translate(stayLabel)}
          </Button.Solid>
        </ModalFooter>
      </ModalSection>
    </Modal.ComposeRoot>
  );
}
