import { FormikErrors } from 'formik';
import React from 'react';
import tw, { styled } from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import { ShipperOrderActivityFormValues } from '../../../model/ShipperOrder.model';
import { promiseToVoid } from '../../../util/helper.util';
import { Button, Icon } from '../../atom';

// #region GOODS TABLE
const ErrorContainer = tw.div`font-sans text-[12px] leading-[20px] tracking-[0.2px] mx-4 text-status-alert`;
const Container = styled.div((props: { hasActivities?: boolean }) => [
  tw`mt-5`,
  !props.hasActivities && tw`mt-0`,
]);
// #endregion

export interface Props {
  translator: UseTranslator;
  isMaxExceeded?: boolean;
  hasActivities?: boolean;
  activitiesError?:
    | string
    | string[]
    | FormikErrors<ShipperOrderActivityFormValues>[];
  handleAddMoreActivity: () => Promise<void>;
}

export default function SOCreateActivityFormAction({
  translator,
  isMaxExceeded,
  hasActivities,
  activitiesError,
  handleAddMoreActivity,
}: Props) {
  return (
    <Container hasActivities={hasActivities}>
      <Button.Icon
        disabled={isMaxExceeded}
        icon={<Icon.Add />}
        label={translator.translate('Add Other Activity')}
        action={() => promiseToVoid([handleAddMoreActivity()])}
      />
      {typeof activitiesError === 'string' && (
        <ErrorContainer>{activitiesError}</ErrorContainer>
      )}
    </Container>
  );
}
