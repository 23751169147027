import React, { cloneElement } from 'react';
import tw from 'twin.macro';
import { Icon, Paper, Text } from '../../atom';

type Props = {
  label: string;
  value: React.ReactNode;
  unit: string;
  icon?: React.ReactElement;
  image: React.ReactElement;
  onClick: () => void;
};

const Container = tw(
  Paper,
)`flex items-center box-border relative cursor-pointer duration-200 hover:(bg-orange-hover bg-opacity-50)`;
const ContentItem = tw.div`flex-1 p-4 pb-3.5`;
const Label = tw(Text.HeadingFour)`mb-3`;
const LabelFirstLine = tw.span`float-left`;
const LargeText = tw.span`text-[36px] leading-[35px] tracking-[0] mr-2`;
const Arrow = tw(Icon.Arrow)`text-orange ml-2.5 float-left align-top`;
const IconContainer = tw.div`absolute top[18px] right-4`;

export function Card({ label, value, unit, icon, image, onClick }: Props) {
  const adjustLabel = () => {
    const words = label.split(' ');
    let firstLine = words[0];
    if (words.length === 1) {
      return { firstLine, after: null };
    }
    let i = 1;
    while (firstLine.length + words[i].length < 12) {
      firstLine = `${firstLine} ${words[i]}`;
      i++;
    }
    let after = words[i];
    i++;
    while (i < words.length) {
      after = `${after} ${words[i]}`;
      i++;
    }
    return { firstLine, after };
  };

  return (
    <Container onClick={onClick}>
      <ContentItem>
        <Label>
          <LabelFirstLine>{adjustLabel().firstLine}</LabelFirstLine>
          <Arrow />
          <br />
          {adjustLabel().after ?? <br />}
        </Label>
        <Text.BodyOne>
          <LargeText>{value}</LargeText>
          {unit}
        </Text.BodyOne>
      </ContentItem>
      {!!icon && (
        <IconContainer>
          {cloneElement(icon, { width: 32, height: 32 })}
        </IconContainer>
      )}
      {image}
    </Container>
  );
}

export default Card;
