import React from 'react';
import { Navigate } from 'react-router-dom';
import tw from 'twin.macro';
import { Icon, IconButton, TableNoData } from '../../component/atom';
import { BodySixteen, HeadingThree } from '../../component/atom/Text/Text.atom';
import { BeforeLeaveAlertModal, Modal } from '../../component/molecule';
import DrivingContestStatusBadge from '../../component/molecule/DrivingContestStatusBadge/DrivingContestStatusBadge.molecule';
import { UploadFileModal } from '../../component/organism';
import { BulkActionLayout } from '../../component/template';
import { mobileRedirectionRoutePath } from '../../config/router/router.config';
import useScreenDetection from '../../hook/useScreenDetection/useScreenDetection.hook';
import { DrivingContestStatus } from '../../model/DrivingContest.model';
import useJOCreateBulkViewModel from './JOCreateBulk.viewModel';

const ModalStyle = tw`p-0 max-w-[1024px] `;
const ModalTitleStyle = tw`h-16 p-4 block mb-0`;

export default function JOCreateBulkView() {
  const { translator, uploadModal, bulkLogic, bulkTable, soListModal } =
    useJOCreateBulkViewModel();

  const { isMobile } = useScreenDetection();

  if (isMobile) {
    return <Navigate to={mobileRedirectionRoutePath} replace={true} />;
  }

  return (
    <>
      <BulkActionLayout
        actionLabel={translator.translate('to start the registration process.')}
        descriptionTooltipLabel={bulkLogic.filename}
        descriptionLabel={translator.translate(
          'Make sure the list below is the job order list that you want to create. The job order created will be active and are ready to be shipped.',
        )}
        headerLabel={bulkLogic.truncatedFilename}
        cancelLabel={translator.translate('Re-upload')}
        submitLabel={translator.translate('Register')}
        doneLabel={translator.translate('Done')}
        table={bulkTable.table}
        entityLength={bulkLogic.jobOrdersWithStatusDetail.length}
        showDoneBtn={bulkLogic.showDoneBtn}
        isLoading={bulkLogic.mutationsIsLoading}
        noDataPage={
          <TableNoData
            icon={<Icon.NoDataFound />}
            titleLabel={translator.translate(
              "You don't have any imported Job Orders that you want to register",
            )}
            descriptionLabel={translator.translate(
              'Try to import some Job Orders by clicking the Re-upload button, or you can click the button below',
            )}
            actionLabel={translator.translate('Re-upload')}
            onClickAction={uploadModal.onOpenUploadModal}
          />
        }
        onCancel={uploadModal.onOpenUploadModal}
        onBack={bulkLogic.handleDone}
        onSubmit={bulkLogic.handleAddBulk}
        onDone={bulkLogic.handleDone}
      />

      {soListModal.soListInfoModal && (
        <Modal.Regular
          onOverlayClick={soListModal.onCloseSoListModal}
          css={ModalStyle}
        >
          <div tw="flex flex-col">
            <div tw="flex flex-1 px-5 py-4 items-center bg-beige-bg border-b border-beige-lines rounded-t-md">
              <HeadingThree tw="flex flex-1 text-black-primary">{`${translator.translate(
                'Total Shipper Order',
              )} (${
                soListModal.soListInfoModal.soNumber.length
              })`}</HeadingThree>
              <IconButton onClick={soListModal.onCloseSoListModal}>
                <Icon.Close tw="w-5 h-5 text-neutral-500" />
              </IconButton>
            </div>
            <div>
              <div tw="flex flex-col gap-6 px-5 py-6 max-h-[500px] overflow-y-scroll">
                {soListModal.soListInfoModal.soNumber.map((soNumber, idx) => {
                  const isStatusFailed =
                    soListModal.soListInfoModal?.failedSONumber?.includes(
                      soNumber,
                    );

                  return (
                    <div
                      key={`so-${idx + 1}-${soNumber}`}
                      tw="flex flex-col flex-1 border border-beige-lines px-4 py-3 rounded-md gap-2.5"
                    >
                      <div tw="flex flex-1">
                        <HeadingThree tw="flex flex-1 text-black-primary font-bold">
                          {soNumber}
                        </HeadingThree>
                        <DrivingContestStatusBadge
                          containerStyle={tw`self-center`}
                          status={
                            isStatusFailed
                              ? DrivingContestStatus.NOT_READY
                              : DrivingContestStatus.READY
                          }
                          statusLabel={
                            isStatusFailed
                              ? translator.translate('Incorrect')
                              : translator.translate('Correct')
                          }
                        />
                      </div>
                      {isStatusFailed && (
                        <BodySixteen tw="text-black-primary">
                          <span tw="font-bold mr-1">
                            {translator.translate(
                              'Incorrect Shipper Order Number.',
                            )}
                          </span>
                          {translator.translate(
                            'Make sure the number matches the Shipper Order number registered in your account.',
                          )}
                        </BodySixteen>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </Modal.Regular>
      )}

      {uploadModal.showUploadModal && (
        <UploadFileModal
          filename={uploadModal.filename}
          error={uploadModal.error}
          dragActive={uploadModal.dragActive}
          isLoading={uploadModal.isLoading}
          requiredLabels={uploadModal.requiredLabels}
          sampleLink={uploadModal.sampleLink}
          onClose={uploadModal.onCloseUploadModal}
          handleReupload={uploadModal.handleReupload}
          handleDrag={uploadModal.handleDrag}
          handleDrop={uploadModal.handleDrop}
          handleChange={uploadModal.handleChange}
          subtitleLabel={uploadModal.subtitleLabel}
        />
      )}

      <BeforeLeaveAlertModal hasUnsavedChanges={bulkLogic.hasUnsavedChanges} />
    </>
  );
}
