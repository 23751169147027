import React from 'react';
import tw, { styled, theme, TwStyle } from 'twin.macro';
import { UseTranslator } from '../../../hook/useTranslator.hook';

const Container = tw.div`grid`;
export type SOGoodsColumnType<T> = {
  accessor: keyof T;
  label: string;
  cell?: (row: T, translator?: UseTranslator) => React.ReactNode;
  align?: 'left' | 'right' | 'center' | 'justify' | 'char';
};

type WithId<T> = T & { id?: string };
type Props<T> = {
  css?: TwStyle;
  rows: WithId<T>[];
  columns: SOGoodsColumnType<T>[];
  total: { label: string; weight: string; volume: string };
  borderColor?: string;
  translator: UseTranslator;
};

const Table = styled.table<{ borderColor?: string }>(({ borderColor }) => [
  tw`font-sans text-[14px] border-collapse table-auto w-full`,
  `
    & thead tr {
      border-bottom: 1px solid ${borderColor || `${theme`colors.beige.lines`}`};
    }
    & thead tr th {
      font-weight: 600;
      padding: 8px;
    }
    & tbody tr {
      border-bottom: 1px solid ${borderColor || `${theme`colors.beige.lines`}`};
    }
   
    & tbody tr td {
      padding: 8px;
    }
  `,
]);

export default function SOActivityTable<T>({
  css,
  rows,
  columns,
  total,
  borderColor,
  translator,
}: Props<T>) {
  return (
    <Container css={css}>
      <Table borderColor={borderColor}>
        <thead>
          <tr>
            {columns.map((c) => (
              <th
                align={!c.align ? 'left' : c.align}
                key={`header-cell-${c.accessor as string}`}
              >
                {translator.translate(c.label)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.length > 0
            ? rows.map((r, i) => (
                <tr key={`body-row-${r?.id || i}`}>
                  {columns.map((c, j) => {
                    const val = r[c.accessor] || '-';
                    return (
                      <td
                        key={`body-cell-${String(c.accessor) || j}`}
                        align={!c.align ? 'left' : c.align}
                        title={String(val)}
                      >
                        {c?.cell ? c.cell(r, translator) : r[c.accessor] || '-'}
                      </td>
                    );
                  })}
                </tr>
              ))
            : null}
        </tbody>
        <tfoot>
          <tr tw="font-bold">
            <td colSpan={4} tw="p-2" title={translator.translate(total.label)}>
              {translator.translate(total.label)}
            </td>
            <td align="right" tw="p-2" title={total.weight}>
              <div tw="max-width[80px] overflow-ellipsis overflow-hidden word-wrap[normal] whitespace-nowrap">
                {total.weight}
              </div>
            </td>
            <td align="right" tw="p-2" title={total.volume}>
              <div tw="max-width[90px] overflow-ellipsis overflow-hidden word-wrap[normal] whitespace-nowrap">
                {total.volume}
              </div>
            </td>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
}
