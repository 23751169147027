import React, { useMemo } from 'react';
import tw, { styled } from 'twin.macro';
import { SOActivityType } from '../../../constant';
import { SelectedActivityId } from '../../../hook/jobOrderDetail/useJODetailSheet.hook';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import {
  JOActivityInfo,
  JobOrderInfoDelivery,
  JobOrderInfoDeliverySOActivityStatus,
} from '../../../model/JobOrder.model';
import { Goods, ShipperOrderEvent } from '../../../model/ShipperOrder.model';
import { getFullDateTimeFormat } from '../../../util/date.util';
import { numberFormatter } from '../../../util/formatter.util';
import { mapJODetailSOActivityTypeToLabel } from '../../../util/jobOrder.util';
import { generateJOSOMobileHeaderItem } from '../../../util/jobOrderDetail/jobOrderDetail.util';
import {
  getActivityEventTypeLabel,
  getActivityGoodsTypeLabel,
  getNoteDescriptionFromActivity,
  getNoteTitleFromActivity,
} from '../../../util/shipperOrder.util';
import { CircleInactive } from '../../atom/Icon/Icon.atom';
import {
  BodyFourteen,
  BodyTwelve,
  HeadingFive,
  HeadingFour,
} from '../../atom/Text/Text.atom';
import { CollapsiblePanel, WrapTextContainer } from '../../molecule';

// #region STYLED
const Container = tw.div`p-4 gap-4 flex flex-col`;
const SectionItem = tw.div`p-3 border-b border-b-beige-lines`;

const ActivityIconContainer = tw.div`mx-1 w-4 rounded-full flex justify-center z-index[1]`;

const ActivityFinishedSuccessCircle = styled.div<{ show: boolean }>(
  ({ show }) => [
    tw`invisible w-4 rounded-full bg-status-success h-4 z-index[1]`,
    show && tw`visible`,
  ],
);

const GalleryContainer = tw.div`grid gap-1 grid-cols-[repeat(3, minmax(100px,1fr))] grid-template-rows[repeat(auto-fit, minmax(100px,1fr))]`;

const ActivityStatusContainer = tw.div`flex absolute flex-col items-center w-6 self-center  -left-1 top-1`;
const TimelineContainer = tw.div`relative flex mb-1`;
const TimelineLocation = tw.div`ml-5 flex flex-col flex-1 min-w-0`;
const Line = styled.div<{ isLast?: boolean }>(({ isLast }) => [
  tw`w-[1px] h-[90%] absolute border-l border-dashed border-grey-three top-5 left-[7.5px]`,
  isLast && tw`border-0`,
]);
// #endregion

// #region INTERFACES

type Props = {
  translator: UseTranslator;
  selectedSO: JobOrderInfoDelivery;
  handleClickActivityPhoto: (_activity: SelectedActivityId) => void;
};

type ActivityItemProps = {
  translator: UseTranslator;
  activity: JOActivityInfo;
  index: number;
  handleClickActivityPhoto: (_activity: SelectedActivityId) => void;
};

type TimelineProps = {
  translator: UseTranslator;
  locationName: string;
  event: ShipperOrderEvent;
  isLast?: boolean;
  notesTitle: string;
  notes?: string;
  isFirst: boolean;
};

type GoodProps = {
  translator: UseTranslator;
  item: Goods;
  isLast?: boolean;
};
// #endregion

const GoodContentItemWrapper = tw.div`flex-1 overflow-wrap['anywhere']`;

function GoodContentItem({ translator, item, isLast }: GoodProps) {
  return (
    <div tw="mt-2">
      <div>
        <HeadingFive>
          {translator.translate(getActivityGoodsTypeLabel(item.type))}
        </HeadingFive>
      </div>
      {!!item.description && (
        <WrapTextContainer tw="bg-assigned-dark" maxLine={3} showExpandButton>
          <BodyTwelve tw="text-grey-two">{item.description}</BodyTwelve>
        </WrapTextContainer>
      )}

      <div
        tw="grid mt-2 pb-2 grid-cols-[repeat(3,minmax(calc(100%/3),1fr));]"
        css={[!isLast && tw` border-b border-b-beige-lines`]}
      >
        <GoodContentItemWrapper>
          <div>
            <BodyTwelve tw="text-grey-three">
              {translator.translate('Quantity')}
            </BodyTwelve>
          </div>
          <WrapTextContainer isBreakWord maxLine={2}>
            <BodyTwelve>
              {item.quantity ? `${numberFormatter(item.quantity)}` : '-'}
            </BodyTwelve>
            {item.quantity && (
              <BodyTwelve>{` ${item?.uom || 'Unit'}`}</BodyTwelve>
            )}
          </WrapTextContainer>
        </GoodContentItemWrapper>
        <GoodContentItemWrapper>
          <div>
            <BodyTwelve tw="text-grey-three">
              {translator.translate('Weight')}
            </BodyTwelve>
          </div>
          <div>
            <BodyTwelve>
              {item.weight ? `${numberFormatter(item.weight)} Kg` : '-'}
            </BodyTwelve>
          </div>
        </GoodContentItemWrapper>
        <GoodContentItemWrapper>
          <div>
            <BodyTwelve tw="text-grey-three">
              {translator.translate('Volume')}
            </BodyTwelve>
          </div>
          <div>
            <BodyTwelve>
              {item.volume ? `${numberFormatter(item.volume)} m3` : '-'}
            </BodyTwelve>
          </div>
        </GoodContentItemWrapper>
      </div>
    </div>
  );
}

function TimelineContentItem({
  translator,
  event,
  locationName,
  notesTitle,
  notes,
  isLast,
  isFirst,
}: TimelineProps) {
  return (
    <TimelineContainer>
      <div>
        <ActivityStatusContainer>
          {isFirst ? (
            <ActivityFinishedSuccessCircle show />
          ) : (
            <ActivityIconContainer>
              <CircleInactive />
            </ActivityIconContainer>
          )}
        </ActivityStatusContainer>
        <Line isLast={isLast} />
      </div>

      <TimelineLocation>
        <WrapTextContainer maxLine={1}>
          <HeadingFive tw="font-bold">
            {getActivityEventTypeLabel({
              type: event.eventType,
              locationName,
              noteTitle: notesTitle,
              translator,
            })}
          </HeadingFive>
        </WrapTextContainer>

        <div tw="-mt-1">
          <BodyTwelve tw="text-grey-three text-[12px]">
            {getFullDateTimeFormat(event.eventTime * 1000, true)}
          </BodyTwelve>
        </div>

        {notes && (
          <WrapTextContainer maxLine={3} showExpandButton>
            <BodyFourteen>{notes}</BodyFourteen>
          </WrapTextContainer>
        )}
      </TimelineLocation>
    </TimelineContainer>
  );
}

function ActivityItem({
  translator,
  activity,
  index,
  handleClickActivityPhoto,
}: ActivityItemProps) {
  return (
    <CollapsiblePanel
      collapsible
      headerStyle={tw`px-2`}
      expandedHeaderStyle={tw`bg-orange-hover`}
      contentStyle={tw`p-0`}
      isCollapsed={
        (activity.status as unknown as JobOrderInfoDeliverySOActivityStatus) ===
        JobOrderInfoDeliverySOActivityStatus.DELIVERED
      }
      title={`${translator.translate('Activity')} ${
        index + 1
      }· ${mapJODetailSOActivityTypeToLabel({
        activityType: activity.type || activity.soActivity?.type,
        translator,
      })} ${
        activity.soActivity?.goods?.length
          ? `· ${activity.soActivity?.goods?.length} ${translator.translate(
              'Goods',
            )}`
          : ''
      }`}
    >
      <div>
        <SectionItem>
          {generateJOSOMobileHeaderItem(translator, activity).map((data) => (
            <div key={data.value}>
              <div>
                <BodyFourteen tw="text-grey-three">
                  {translator.translate(data.label)}
                </BodyFourteen>
              </div>
              <WrapTextContainer>
                <BodyFourteen tw="word-break[break-all]">
                  {data.value}
                </BodyFourteen>
              </WrapTextContainer>
            </div>
          ))}
        </SectionItem>
        {!!activity.events?.length && (
          <SectionItem>
            <div tw="mb-1">
              <HeadingFour>{translator.translate('Timeline')}</HeadingFour>
            </div>
            {activity.events.map((event, i, arr) => (
              // prevent duplicate key
              <div key={`${event.eventTime}-${i}`}>
                <TimelineContentItem
                  isFirst={i === 0}
                  translator={translator}
                  isLast={i === arr.length - 1}
                  event={event}
                  locationName={
                    activity.soActivity?.location?.name ||
                    activity.location?.name ||
                    ''
                  }
                  notesTitle={getNoteTitleFromActivity(
                    translator,
                    event.eventTime,
                    activity.notes,
                  )}
                  notes={getNoteDescriptionFromActivity(
                    event.eventTime,
                    activity.notes,
                  )}
                />
              </div>
            ))}
          </SectionItem>
        )}
        {!!activity.photos.length && (
          <SectionItem>
            <div tw="mb-2">
              <HeadingFour>
                {translator.translate('Proof of Activity')}
              </HeadingFour>
            </div>
            <GalleryContainer>
              {activity.photos.map((item, idx) => (
                <button
                  key={`${item.photo.id}-${item.photoId || ''}`}
                  type="button"
                  onClick={() =>
                    handleClickActivityPhoto({
                      idx,
                      activityId: activity.id,
                      totalPhotos: activity.photos.length,
                    })
                  }
                >
                  <img
                    loading="lazy"
                    src={item.photo.accessUrl}
                    alt={item.photo.fileName}
                    tw="object-cover aspect-ratio[1]  rounded-md w-full"
                  />
                </button>
              ))}
            </GalleryContainer>
          </SectionItem>
        )}
        {!!activity.soActivity?.goods?.length && (
          <SectionItem>
            <div tw="pb-2 border-b border-b-beige-lines">
              <HeadingFour>{translator.translate('Goods Details')}</HeadingFour>
            </div>
            {activity.soActivity.goods.map((good, idx, arr) => (
              <GoodContentItem
                key={good.id}
                translator={translator}
                item={good}
                isLast={idx + 1 === arr.length}
              />
            ))}
          </SectionItem>
        )}
      </div>
    </CollapsiblePanel>
  );
}

export default function JOSODetailMobileContent({
  translator,
  selectedSO,
  handleClickActivityPhoto,
}: Props) {
  const activities = useMemo(() => {
    if (!selectedSO.activities.length) return [];
    return selectedSO.activities
      .slice()
      .sort((a, b) => a.index - b.index)
      .map((item, idx) => {
        if (!selectedSO.shipperOrder.isTransitable) return item;

        const goodsData = selectedSO.shipperOrder.activities[idx]?.goods;

        const formattedActivity: JOActivityInfo = {
          ...item,
          soActivity: {
            index: idx,
            location: item.soActivity?.location,
            goods: goodsData,
            type: idx === 0 ? SOActivityType.PICK_UP : SOActivityType.DROP_OFF,
          },
        };
        return formattedActivity;
      });
  }, [
    selectedSO.activities,
    selectedSO.shipperOrder.activities,
    selectedSO.shipperOrder.isTransitable,
  ]);
  return (
    <Container>
      {activities.map((item, index) => (
        <ActivityItem
          translator={translator}
          activity={item}
          key={item.id}
          index={index}
          handleClickActivityPhoto={handleClickActivityPhoto}
        />
      ))}
    </Container>
  );
}
