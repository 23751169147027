import {
  ColumnDef,
  getCoreRowModel,
  useReactTable,
} from '@tanstack/react-table';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { Text } from '../../../component/atom';
import {
  BulkActionStatus,
  CustomTableRowWithPopover,
} from '../../../component/molecule';
import { BulkActionStatusEnum } from '../../../constant';
import useTranslator from '../../../hook/useTranslator.hook';
import { ErrorDetail } from '../../../service/api.endpoint';
import { numberFormatter } from '../../../util/formatter.util';
import {
  mapBulkActionStatusToLabel,
  mapErrorMessages,
} from '../../../util/helper.util';
import {
  createBulkJOEntity,
  UseJOCreateBulkLogic,
} from './useJOCreateBulkLogic.hook';
import { UseJOSOListModal } from './useJOSOListModal.hook';

// #region TYPES
type Props = {
  bulkLogic: UseJOCreateBulkLogic;
  soListModal: UseJOSOListModal;
};
// #endregion

export default function useJOCreateBulkTable({
  bulkLogic: { jobOrdersWithStatusDetail, showStatus },
  soListModal: { onOpenSoListModal },
}: Props) {
  const translator = useTranslator();

  const columns = useMemo<ColumnDef<createBulkJOEntity>[]>(
    () => [
      {
        size: 130,
        header: translator.translate('JO Number'),
        accessorKey: 'joNumber',
        cell: ({ getValue }) => {
          const joNumber = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={joNumber ?? '-'}
              containerStyle={tw`max-w-[148px]`}
            />
          );
        },
      },
      {
        size: 150,
        header: translator.translate('Driver Name'),
        accessorKey: 'driverName',
        cell: ({ getValue }) => {
          const driverName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={driverName ?? '-'}
              containerStyle={tw`max-w-[164px]`}
            />
          );
        },
      },
      {
        size: 130,
        header: translator.translate('Vehicle'),
        accessorKey: 'vehicleName',
        cell: ({ getValue }) => {
          const vehicleName = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={vehicleName ?? '-'}
              containerStyle={tw`max-w-[144px]`}
            />
          );
        },
      },
      {
        size: 130,
        header: translator.translate('Travel Budget'),
        accessorKey: 'travelExpense',
        cell: ({ getValue }) => {
          const travelExpense = getValue() as string;

          return (
            <CustomTableRowWithPopover
              primaryLabel={
                travelExpense ? `Rp ${numberFormatter(travelExpense)}` : '-'
              }
              containerStyle={tw`max-w-[144px]`}
            />
          );
        },
      },
      {
        size: 170,
        header: translator.translate('Total Shipper Order'),
        accessorFn: (jobOrder) => jobOrder,
        cell: ({ getValue }) => {
          const jobOrder = getValue() as createBulkJOEntity;
          const totalSO = jobOrder.soNumbers.length;
          return (
            <Text.Paragraph
              onClick={() =>
                onOpenSoListModal(jobOrder.soNumbers, jobOrder.failedSoNumber)
              }
              tw="cursor-pointer text-orange font-bold"
            >{`${totalSO} Shipper Order`}</Text.Paragraph>
          );
        },
      },
      ...(showStatus
        ? [
            {
              size: 110,
              header: translator.translate('Status'),
              accessorKey: '_actionStatus',
              cell: ({
                getValue,
              }: { getValue: () => BulkActionStatusEnum }) => (
                <BulkActionStatus
                  status={getValue()}
                  statusLabel={translator.translate(
                    mapBulkActionStatusToLabel(getValue()),
                  )}
                />
              ),
            },
            {
              size: 250,
              header: translator.translate('Detail'),
              accessorKey: '_actionDetail',
              cell: ({
                getValue,
              }: { getValue: () => string | ErrorDetail[] }) => {
                const accessor = getValue();
                const label =
                  typeof accessor === 'string'
                    ? translator.translate(accessor)
                    : mapErrorMessages(translator, accessor);

                return (
                  <CustomTableRowWithPopover
                    primaryLabel={label}
                    containerStyle={tw`max-w-[290px]`}
                  />
                );
              },
            },
          ]
        : []),
    ],
    [showStatus, onOpenSoListModal, translator],
  );

  const table = useReactTable({
    data: jobOrdersWithStatusDetail,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return {
    table,
  };
}
