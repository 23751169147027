import React from 'react';
import { RouteConfig } from '../../config/router/router.config';
import JOCreateBulkView from './JOCreateBulk.view';

export type JOCreateBulk = {
  'Nomor JO*': string;
  'Nomor Segel': string;
  'Nomor SO*': string;
  'Driver*': string;
  'Kendaraan*': string;
  'Uang Jalan': number;
};

export type JOCreateBulkRouteParam = {
  filename: string;
  jobOrders: JOCreateBulk[];
};

export const joCreateBulkRoute: RouteConfig = {
  name: 'Import Job Order',
  Component: React.lazy(() => import('./JOCreateBulk.view')),
  NormalComponent: <JOCreateBulkView />,
  path: '/job-order/create-bulk',
  isPrivate: true,

  props: {
    isUnclickable: true,
  },
};
