import React, { useCallback, useMemo } from 'react';
import tw from 'twin.macro';
import {
  getHOTrackingPOAPhotos,
  getSOTrackingPOAPhotos,
  soTimelinePhotoActivitySelector,
} from '../../../util/tracking/trackingTimeline.util';
import { Text } from '../../atom';
import {
  OnTimelineContentImageClick,
  TimelineContentDataPhoto,
  TimelineContentPhoto,
} from './TimelineContentItem.type';

//#region COMPONENTS
const Container = tw.div`grid grid-cols-[32px minmax(128px,100%)]`;
const TimelineMarker = tw.div`w-4 justify-center flex pt-1 mr-2.5 relative`;
const TimelinePath = tw.div`border-l absolute top-[18px] left-[8px] h-full border-dashed border-grey-four z-0`;
const TimelineDot = tw.div`w-[11px] h-[11px] rounded-full bg-grey-three relative`;
const TimelineDotContainer = tw.div``;
const TimelineInfoContainer = tw.div`flex flex-col`;
const TimelineTitleContainer = tw.div`flex items-center overflow-wrap[anywhere]`;
const TimelineSubtitleContainer = tw.div`flex items-center w-full overflow-wrap[anywhere]`;
const ImagesContainer = tw.div`grid grid-cols-[repeat(5, minmax(0, 96px))] gap-4 place-items-center`;
const ImageContainer = tw.button`h-24 w-24 flex justify-center rounded overflow-hidden cursor-pointer bg-black`;
const Image = tw.img`h-24 w-24 object-cover`;

//#endregion

type Props = {
  title: string;
  value: string;
  showFootprint?: boolean;
  isLastItem?: boolean;
  isFirstItem?: boolean;
  data?: TimelineContentDataPhoto;
  onImageClick?: OnTimelineContentImageClick;
};
function TimelineContentItem({
  title,
  value,
  showFootprint,
  isLastItem,
  isFirstItem,
  data,
  onImageClick,
}: Props) {
  const photos = useMemo((): TimelineContentPhoto[] => {
    if (!data?.photos?.length) return [];
    if (data.type === 'so') return getSOTrackingPOAPhotos(data);

    return getHOTrackingPOAPhotos(data);
  }, [data]);

  const renderMapPhoto = useCallback(
    ({ id, url }: TimelineContentPhoto, photoIndex: number) => {
      if (!data || !onImageClick) return;

      return (
        <ImageContainer
          key={id}
          type="button"
          onClick={() =>
            onImageClick?.(
              photos.map((photo) => ({
                accessUrl: photo.url,
                createdAt: data.createdAt || 0,
                id,
                // photo does not contains props down below but it is required to fill these properties based on interface
                fileName: '',
                mimeType: 'jpeg',
                size: 0,
                ...(data.type === 'ho' &&
                  data?.caption && { caption: data.caption }),
              })),
              photoIndex,
              data.type === 'so'
                ? photos.map(soTimelinePhotoActivitySelector)
                : undefined,
            )
          }
          css={[data && photoIndex >= photos.length - 1 && tw`mr-0`]}
        >
          <Image src={url} alt={url} />
        </ImageContainer>
      );
    },
    [data, onImageClick, photos],
  );

  return (
    <Container>
      <TimelineMarker>
        <TimelineDotContainer>
          <TimelineDot
            css={[isFirstItem && tw`bg-delivered-dark w-[15px] h-[15px] z-10`]}
          />
        </TimelineDotContainer>
        {showFootprint && !isLastItem && <TimelinePath />}
      </TimelineMarker>
      <TimelineInfoContainer>
        <TimelineTitleContainer>
          <Text.HeadingFive css={[!isFirstItem && tw`text-grey-three`]}>
            {title}
          </Text.HeadingFive>
        </TimelineTitleContainer>
        <TimelineSubtitleContainer>
          <Text.BodyTwelve
            tw="whitespace-nowrap overflow-hidden overflow-ellipsis"
            css={[!isFirstItem && tw`text-grey-three`]}
          >
            {value}
          </Text.BodyTwelve>
        </TimelineSubtitleContainer>
        {!!data && onImageClick && (
          <ImagesContainer>{photos.map(renderMapPhoto)}</ImagesContainer>
        )}
      </TimelineInfoContainer>
    </Container>
  );
}

export default TimelineContentItem;
