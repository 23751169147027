import React from 'react';
import tw from 'twin.macro';
import { Icon, IconButton, Paper, Text } from '../../atom';

type Props = React.ComponentProps<'div'> & {
  title: string;
  body?: string;
  icon?: 'info' | 'warning' | 'success';
  onClose?: () => void;
};

const Container = tw(
  Paper,
)`p-4 box-border width[480px] flex overflow-hidden bg-white`;
const Body = tw(Text.BodyOne)`text-grey-two`;

export function Notification({ title, body, icon, onClose, ...props }: Props) {
  return (
    <Container {...props}>
      {!!icon && (
        <div tw="mr-5">
          {icon === 'warning' && (
            <Icon.Error width={28} height={28} tw="text-status-alert" />
          )}
          {icon === 'info' && (
            <Icon.Info width={28} height={28} tw="text-blue-hyperlink-text" />
          )}
          {icon === 'success' && (
            <Icon.CheckCircle width={28} height={28} tw="text-status-success" />
          )}
        </div>
      )}
      <div tw="flex-1">
        <div tw="flex items-start">
          <Text.HeadingThree tw="flex-1 leading-[32px]">
            {title}
          </Text.HeadingThree>
          <IconButton tw="text-black -m-2" onClick={onClose}>
            <Icon.Close width={28} height={28} />
          </IconButton>
        </div>
        <Body>{body}</Body>
      </div>
      <div> </div>
    </Container>
  );
}

export default Notification;
