import capitalize from 'lodash/capitalize';
import React, { useMemo } from 'react';
import tw from 'twin.macro';
import { SupportedLanguage } from '../../../config/locale/locale.config';
import { UseTranslator } from '../../../hook/useTranslator.hook';
import {
  Goods,
  SOTracking,
  SOTrackingActivityType,
} from '../../../model/ShipperOrder.model';
import { numberFormatter } from '../../../util/formatter.util';
import {
  formatText,
  getGoodsDetailLabel,
} from '../../../util/tracking/trackingTimeline.util';
import { Text } from '../../atom';
import CollapsiblePanel from '../CollapsiblePanel/CollapsiblePanel.molecule';
import WrapTextContainer from '../WrapTextContainer/WrapTextContainer.molecule';

type Props = {
  data: SOTracking;
  translator: UseTranslator;
  forceLang?: SupportedLanguage;
};

type TrackingGoodsItemProps = {
  title?: string;
  goods: Goods[];
  translator: UseTranslator;
  forceLang?: SupportedLanguage;
};

const panelStyle = {
  header: tw`py-2.5 px-4 overflow-wrap[anywhere]`,
  content: tw`bg-white px-4 py-3`,
  container: tw`rounded-md overflow-hidden bg-white border-0`,
  shippingTimelineContainer: tw`rounded-md overflow-visible bg-white`,
  shippingTimelineContent: tw`rounded-md`,
  shippingTimelineHeader: tw`rounded-md bg-white flex items-center`,
  goodsDetailContainer: tw`rounded-md overflow-hidden bg-white p-0`,
};

const GoodContentItemWrapper = tw.div`flex-1 overflow-wrap['anywhere']`;

function TrackingGoodsItem({
  title = '-',
  goods,
  translator,
  forceLang,
}: TrackingGoodsItemProps) {
  return (
    <CollapsiblePanel
      title={title}
      headerStyle={panelStyle.header}
      contentStyle={panelStyle.goodsDetailContainer}
      containerStyle={panelStyle.container}
    >
      {goods.map((good, idx) => (
        <div
          tw="not-last:(border-b border-b-beige-lines)"
          key={`tracking-goods-item-${good.id ?? idx}`}
        >
          <div tw="px-4 py-3 flex flex-col gap-1">
            <Text.HeadingFive>
              {translator.translate(capitalize(good.type), forceLang)}
            </Text.HeadingFive>
            {!!good.description && (
              <Text.BodyFourteen>{good.description}</Text.BodyFourteen>
            )}
            <div tw="grid grid-cols-[repeat(3,minmax(calc(100%/3),1fr));]">
              <GoodContentItemWrapper>
                <div>
                  <Text.BodyTwelve tw="text-grey-three">
                    {translator.translate('Quantity', forceLang)}
                  </Text.BodyTwelve>
                </div>
                <WrapTextContainer isBreakWord maxLine={2}>
                  <Text.BodyFourteen>
                    {good.quantity
                      ? `${numberFormatter(good.quantity)} ${
                          good?.uom || 'Unit'
                        }`
                      : '-'}
                  </Text.BodyFourteen>
                </WrapTextContainer>
              </GoodContentItemWrapper>
              <GoodContentItemWrapper>
                <div>
                  <Text.BodyTwelve tw="text-grey-three">
                    {translator.translate('Weight', forceLang)}
                  </Text.BodyTwelve>
                </div>
                <div>
                  <Text.BodyFourteen>
                    {good.weight ? `${numberFormatter(good.weight)} Kg` : '-'}
                  </Text.BodyFourteen>
                </div>
              </GoodContentItemWrapper>
              <GoodContentItemWrapper>
                <div>
                  <Text.BodyTwelve tw="text-grey-three">
                    {translator.translate('Volume', forceLang)}
                  </Text.BodyTwelve>
                </div>
                <div>
                  <Text.BodyFourteen>
                    {good.volume ? `${numberFormatter(good.volume)} m3` : '-'}
                  </Text.BodyFourteen>
                </div>
              </GoodContentItemWrapper>
            </div>
          </div>
        </div>
      ))}
    </CollapsiblePanel>
  );
}

function TrackingGoodsMobile({ translator, data, forceLang }: Props) {
  const activities = useMemo(() => {
    if (!data) return [];
    if (data?.isTransitable) return [data.activities[0]];

    return data.activities.filter(
      (v) => v.type !== SOTrackingActivityType.STAND_BY,
    );
  }, [data]);

  const activitiesHasGoods = useMemo(
    () => activities.flatMap((v) => v.goods).length > 0,
    [activities],
  );
  const activityWeightTotal = useMemo(
    () =>
      activities.reduce((totalActivityWeight, record) => {
        const activityGoodsWeight = record.goods.length
          ? record?.goods?.reduce((acc, cur) => acc + (cur?.weight || 0), 0)
          : 0;
        return totalActivityWeight + activityGoodsWeight;
      }, 0),
    [activities],
  );

  const activityVolumeTotal = useMemo(
    () =>
      activities.reduce((totalActivityVolume, record) => {
        const activityGoodsVolume = record.goods.length
          ? record?.goods?.reduce((acc, cur) => acc + (cur?.volume || 0), 0)
          : 0;
        return totalActivityVolume + activityGoodsVolume;
      }, 0),
    [activities],
  );

  if (!activitiesHasGoods) return null;
  return (
    <section tw="flex flex-col gap-3 p-4 bg-white">
      <Text.HeadingFour>
        {translator.translate('Goods Detail', forceLang)}
      </Text.HeadingFour>

      {activitiesHasGoods &&
        activities.map((activity) => (
          <div
            tw="flex flex-col gap-2.5"
            key={`tracking-activity-mobile-${activity.id}`}
          >
            {data.isTransitable && (
              <div tw="flex rounded-md border border-beige-lines">
                <div tw="w-full grid grid-cols-[repeat(2,minmax(152px,1fr))]">
                  <GoodContentItemWrapper tw="px-4 py-2 border-r border-beige-lines">
                    <Text.BodyTwelve tw="text-grey-three">
                      {translator.translate('Total Weight', forceLang)}
                    </Text.BodyTwelve>
                    <WrapTextContainer isBreakWord maxLine={2}>
                      <Text.BodyFourteen>
                        {activityWeightTotal
                          ? `${numberFormatter(activityWeightTotal)} Kg`
                          : '-'}
                      </Text.BodyFourteen>
                    </WrapTextContainer>
                  </GoodContentItemWrapper>

                  <GoodContentItemWrapper tw="px-4 py-2 flex flex-col">
                    <Text.BodyTwelve tw="text-grey-three">
                      {translator.translate('Total Volume', forceLang)}
                    </Text.BodyTwelve>
                    <Text.BodyFourteen>
                      {activityVolumeTotal
                        ? `${numberFormatter(activityVolumeTotal)} m3`
                        : '-'}
                    </Text.BodyFourteen>
                  </GoodContentItemWrapper>
                </div>
              </div>
            )}

            {!!activity.goods.length && (
              <div
                tw="border border-beige-lines rounded-md "
                key={`tracking-activity-goods-mobile-${activity.id}`}
              >
                <TrackingGoodsItem
                  title={
                    data.isTransitable
                      ? translator.translate('Goods Detail', forceLang)
                      : formatText(
                          translator.translate(
                            getGoodsDetailLabel(activity.type),
                            forceLang || undefined,
                          ),
                          activity.locationName,
                        )
                  }
                  translator={translator}
                  forceLang={forceLang}
                  goods={activity.goods}
                />
              </div>
            )}
          </div>
        ))}
    </section>
  );
}

export default TrackingGoodsMobile;
