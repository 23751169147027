import React, { useCallback, useEffect, useMemo, useState } from 'react';
import tw, { styled, theme } from 'twin.macro';
import { Icon, Text } from '../../component/atom';
import { EmptyDataProps } from '../../component/molecule/EmptyData/EmptyData.molecule';
import { SelectionSO } from '../../constant';
import {
  JOAssignmentFormInitialValues,
  JOFormFormatRenderRowsReturnKey,
  JOFormStep,
  JOSOSelectionGroupBy,
  JOSOSelectionGroupByChip,
} from '../../constant/JobOrder.constant';
import { JOSOSelectionColumns } from '../../constant/JobOrderCreate.constant';
import { FormikHookProps } from '../../constant/Types.constant';
import {
  JobOrderForm,
  JOScheduleAssignmentType,
} from '../../model/JobOrder.model';
import { logEvent } from '../../service/analytic/analytic.service';
import { toggle } from '../../util/helper.util';
import {
  capitalizeAssignmentKey,
  getJOSOSelectionGroupByKey,
  getSelectionSOIsExpandedListState,
} from '../../util/jobOrderCreate.util';
import { formatText } from '../../util/tracking/trackingTimeline.util';
import useTranslator from '../useTranslator.hook';
import { UseJOFormControllerHookObj } from './useJOFormController.hook';
import { UseJOFormDeliveryLocationModalHookObj } from './useJOFormDeliveryLocationModal.hook';
import { UseJOFormSORenderRowHookObj } from './useJOFormSORenderRow.hook';

type Props = {
  joFormik: FormikHookProps<JobOrderForm>;
  controller: UseJOFormControllerHookObj;
  locationModalController: UseJOFormDeliveryLocationModalHookObj;
  formatRenderRows: UseJOFormSORenderRowHookObj['formatRenderRows'];
  setIsSOCandidateFormDisplayed: (val: boolean) => void;
  setVehicleDriverTempValue: (state: JOScheduleAssignmentType) => void;
};

const RowExpanded = tw.tr`relative duration-200 animate-slide-in-top group-hover:(bg-orange-hover)`;
const TableColumn = styled.td(({ colIdx }: { colIdx?: number }) => [
  tw`align-top px-5 py-4 bg-white duration-200  border-b border-beige-lines group-hover:(bg-orange-hover) first-of-type:(border-t border-beige-lines) last-of-type:(border-b-0) `,
  colIdx === 0 && tw`pr-[25px]`, // checkbox
  colIdx === 1 && tw`pl-[5px]`, // SO number
]);
const ButtonCheck = styled.button(
  ({ isExpanded }: { isExpanded?: boolean }) => [
    tw`p-1 rounded-full rotate-90 hover:bg-orange-hover text-orange`,
    isExpanded && tw`-rotate-90`,
  ],
);

export default function useJOFormSOSelection({
  joFormik: { values, setValues },
  controller: {
    soController: {
      selectedSOIds,
      selectionSOList,
      handleClearSelectedSO,
      handleDeselectAll,
      handleSelectAll,
      setSelectionSOList,
    },

    handleChangeFormState,
  },
  locationModalController: {
    assignedLastMileList,
    lastMileSelectedSOList,
    deliveryLocationSameAsCurrentLocationList,
  },
  formatRenderRows,
  setVehicleDriverTempValue,
  setIsSOCandidateFormDisplayed,
}: Props) {
  const { translate } = useTranslator();

  const [selectedGroupBy, setSelectedGroupBy] = useState<JOSOSelectionGroupBy>(
    JOSOSelectionGroupBy.CURRENT_LOCATION,
  );
  const [isExpandedList, setIsExpandedList] = useState<string[]>([]);

  const handleOpenCandidate = useCallback(() => {
    setIsSOCandidateFormDisplayed(true);
    logEvent('JobOrder:Create:ChangeSectionToSOCandidate', 'JobOrder');
  }, [setIsSOCandidateFormDisplayed]);

  const handleCloseCandidate = useCallback(() => {
    setIsSOCandidateFormDisplayed(false);
    logEvent('JobOrder:Create:ChangeSectionToSOSelection', 'JobOrder');
  }, [setIsSOCandidateFormDisplayed]);

  const groupByChips = useMemo<JOSOSelectionGroupByChip[] | undefined>(
    () =>
      selectedSOIds.length
        ? undefined
        : [
            {
              id: JOSOSelectionGroupBy.CURRENT_LOCATION,
              label: translate('Current Location'),
              selected:
                selectedGroupBy === JOSOSelectionGroupBy.CURRENT_LOCATION,
              onClick: () =>
                setSelectedGroupBy(JOSOSelectionGroupBy.CURRENT_LOCATION),
            },
            {
              id: JOSOSelectionGroupBy.DELIVERY_LOCATION,
              label: translate('Delivery Location'),
              selected:
                selectedGroupBy === JOSOSelectionGroupBy.DELIVERY_LOCATION,
              onClick: () =>
                setSelectedGroupBy(JOSOSelectionGroupBy.DELIVERY_LOCATION),
            },
            {
              id: JOSOSelectionGroupBy.ASSIGNMENT,
              label: translate('Assignment'),
              selected: selectedGroupBy === JOSOSelectionGroupBy.ASSIGNMENT,
              onClick: () =>
                setSelectedGroupBy(JOSOSelectionGroupBy.ASSIGNMENT),
            },
          ],
    [selectedGroupBy, selectedSOIds.length, translate],
  );

  const selectionDatas = useMemo(
    () =>
      selectionSOList.filter(
        (item) => !!values?.deliveries?.some((v) => v.soId === item.id),
      ),
    [selectionSOList, values?.deliveries],
  );

  const selectionDataTotal = useMemo(
    () => selectionDatas.length,
    [selectionDatas.length],
  );

  const isAllSelectionSelected: boolean = useMemo(
    () =>
      !!selectionDatas?.length &&
      selectionDatas.every((item) => selectedSOIds.includes(item.id)),
    [selectedSOIds, selectionDatas],
  );
  const isIndeterminateSelectionSelected: boolean = useMemo(
    () =>
      isAllSelectionSelected &&
      selectionDatas.some((item) => selectedSOIds.includes(item.id)),
    [isAllSelectionSelected, selectedSOIds, selectionDatas],
  );

  // map through `selectionDatas` list and group it by `selectedGroupBy`
  const groupedSelectionDatas = useMemo<Record<string, SelectionSO[]>>(
    () => getJOSOSelectionGroupByKey(selectionDatas, selectedGroupBy),
    [selectedGroupBy, selectionDatas],
  );

  const selectionByGroup = useMemo(() => {
    if (!selectionDatas.length) return [];

    return Object.entries(groupedSelectionDatas).map(([key, soList]) => {
      const isExpanded = isExpandedList.includes(key);
      const lastMileList = soList.filter(
        (so) => !so.isTransitable || so.deliveryLocation,
      );

      return {
        soList,
        isExpanded,
        id: key,
        check: (
          <ButtonCheck
            isExpanded={isExpanded}
            onClick={() => {
              const newIsExpandedList = toggle(isExpandedList, key);
              setIsExpandedList(newIsExpandedList);
            }}
          >
            <Icon.ChevronSharp height={18} width={18} strokeWidth={2} />
          </ButtonCheck>
        ),
        number: (
          <Text.HeadingFour tw="flex w-[170px]  text-orange">
            {key.length > 18
              ? `${key.slice(0, 18)}...`
              : translate(capitalizeAssignmentKey(key))}
          </Text.HeadingFour>
        ),
        pickupLocation: null,
        dropoffLocation: null,
        currentLocation: null,
        deliveryLocation: null,
        status: null,
        soDate: null,
        shipper: (
          <div tw="flex items-center space-x-2">
            {lastMileList.length === soList.length && (
              <Icon.CheckCircle
                width={20}
                height={20}
                fill={theme`colors.status.success.DEFAULT`}
              />
            )}

            <Text.BodyFourteen tw="w-full font-semibold text-right">
              {formatText(
                translate('%s of %s Assigned'),
                lastMileList.length.toString(),
                soList.length.toString(),
              )}
            </Text.BodyFourteen>
          </div>
        ),
      };
    });
  }, [groupedSelectionDatas, isExpandedList, selectionDatas.length, translate]);

  const cancelSelectionLabel = translate('Cancel');
  const deleteSelectionLabel = translate('Delete');
  const groupByLabel = selectedSOIds.length ? undefined : translate('Group by');
  const disabledButtonHoveredLabel = translate(
    lastMileSelectedSOList.length
      ? 'This operation cannot be performed for SO last mile type'
      : 'Maximum number of selected SO is 100',
  );
  const actionButtonLabel = translate(
    !selectedSOIds.length ? 'Add Shipper Order' : 'Change Delivery Location',
  );

  const renderExpandedPage = useCallback(
    (row: Record<string, unknown>) =>
      row.isExpanded
        ? formatRenderRows(row.soList as SelectionSO[]).map((so) => (
            <RowExpanded
              key={so.id}
              data-testid="Table:ExpandedPage"
              className="group"
            >
              {JOSOSelectionColumns(translate).map((column, colIdx) => (
                <TableColumn colIdx={colIdx} key={so.id + column.accessor}>
                  {so[column.accessor as JOFormFormatRenderRowsReturnKey]}
                </TableColumn>
              ))}
            </RowExpanded>
          ))
        : undefined,
    [formatRenderRows, translate],
  );

  // #region HANDLERS
  const handleBack = useCallback(() => {
    handleChangeFormState(JOFormStep.HEADER);
    handleClearSelectedSO();
    handleCloseCandidate();
  }, [handleChangeFormState, handleClearSelectedSO, handleCloseCandidate]);

  const handleSelectAllSelection = useCallback(() => {
    handleSelectAll(selectionDatas);
  }, [handleSelectAll, selectionDatas]);

  const handleDeselectAllSelection = useCallback(() => {
    handleDeselectAll(selectionDatas);
  }, [handleDeselectAll, selectionDatas]);

  const handleRemoveSOSelection = useCallback(async () => {
    const formattedArr = values?.deliveries?.filter(
      (item) => !selectedSOIds?.some((v) => v === item.soId),
    );
    const formattedArrObj = selectionSOList.filter(
      (item) => !selectedSOIds.includes(item.id),
    );
    await setValues({
      ...values,
      deliveries: formattedArr,
      vehicleId: undefined,
      vehicleOption: undefined,
      schedule: undefined,
      scheduleOption: undefined,
      driverId: undefined,
      driverOption: undefined,
    });
    setVehicleDriverTempValue(JOAssignmentFormInitialValues);
    setSelectionSOList(formattedArrObj);
    handleClearSelectedSO();
  }, [
    values,
    selectedSOIds,
    selectionSOList,
    setValues,
    setVehicleDriverTempValue,
    setSelectionSOList,
    handleClearSelectedSO,
  ]);
  // #endregion

  const emptyData: EmptyDataProps = {
    icon: <Icon.EmptyIllustration tw="pb-2.5" />,
    description: translate(
      "You don't have any Shipper Order on Selected Shipper Order",
    ),
    subDescription: translate(
      'Try adding Shipper Order Candidate by clicking the button on the top right, or you can click the button below:',
    ),
    buttonLabel: translate('Add Shipper Order'),
    onClick: handleOpenCandidate,
  };

  // synchronize `isExpandedList` state whenever `groupedSelectionDatas` state changes
  useEffect(() => {
    setIsExpandedList(
      getSelectionSOIsExpandedListState(groupedSelectionDatas, selectedGroupBy),
    );
  }, [groupedSelectionDatas, selectedGroupBy]);

  const isDisabled = useMemo(
    () =>
      !values?.deliveries?.length ||
      assignedLastMileList.length < selectionDataTotal ||
      !!deliveryLocationSameAsCurrentLocationList.length,
    [
      selectionDataTotal,
      values?.deliveries?.length,
      assignedLastMileList.length,
      deliveryLocationSameAsCurrentLocationList.length,
    ],
  );

  return {
    actionButtonLabel,
    disabledButtonHoveredLabel,
    cancelSelectionLabel,
    deleteSelectionLabel,
    groupByLabel,
    groupByChips,
    emptyData,
    isAllSelectionSelected,
    isIndeterminateSelectionSelected,
    selectionByGroup,
    selectionDataTotal,
    isDisabled,
    renderExpandedPage,
    handleSelectAllSelection,
    handleDeselectAllSelection,
    handleRemoveSOSelection,
    handleOpenCandidate,
    handleBack,
  };
}

export type UseJOFormSOSelectionHookObj = ReturnType<
  typeof useJOFormSOSelection
>;
