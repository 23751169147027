import React from 'react';
import tw, { styled } from 'twin.macro';
import { CustomMapTypeItem } from '../../../model/Map.model';
import { Image } from '../../atom';
import { ExtraStyle } from '../../Type.component';

const MapTypeItem = styled.div(
  (props: { isSelected?: boolean; isMobile?: boolean }) => [
    tw`w-16 h-16 relative flex cursor-pointer mr-1 hover:(border-2 border-orange rounded-md)`,
    props.isMobile && tw`w-10 h-10 hover:(border-none)`,
    props.isSelected &&
      !props.isMobile &&
      tw`border-2 border-orange rounded-md`,
  ],
);
const MapTypeLabelContainer = tw.div`absolute cursor-pointer bottom-0 -translate-x-1/2 left-1/2 w-[62px] whitespace-pre-wrap`;
const MapTypeLabel = tw.div`font-bold text-[10px] tracking-[0.2px] text-white leading-3 text-center`;

interface Props {
  isMobile?: boolean;
  item: CustomMapTypeItem;
  isSelected?: boolean;
  containerStyle?: ExtraStyle;
  handleClickMobile?: () => void;
}

export default function MapTypeControllerItem({
  item,
  isMobile,
  isSelected,
  containerStyle,
  handleClickMobile,
}: Props) {
  if (isMobile)
    return (
      <MapTypeItem
        isMobile
        css={containerStyle}
        isSelected={isSelected}
        onClick={handleClickMobile}
      >
        <Image tw="rounded" src={item.img} />
      </MapTypeItem>
    );

  return (
    <MapTypeItem
      css={containerStyle}
      isSelected={isSelected}
      onClick={item.action}
    >
      <Image tw="rounded" src={item.img} />
      <MapTypeLabelContainer>
        <MapTypeLabel>{item.label}</MapTypeLabel>
      </MapTypeLabelContainer>
    </MapTypeItem>
  );
}
