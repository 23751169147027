import React, { MutableRefObject } from 'react';
import tw, { theme } from 'twin.macro';
import { JOFormStep } from '../../../constant/JobOrder.constant';
import { UseJOFormControllerHookObj } from '../../../hook/jobOrderForm/useJOFormController.hook';
import { JOFormErrorTooltipItem } from '../../../hook/jobOrderForm/useJOFormErrorTooltip.hook';
import useJOFormFooter from '../../../hook/jobOrderForm/useJOFormFooter.hook';
import { UseJOFormStateObj } from '../../../hook/jobOrderForm/useJOFormState.hook';
import { mapJOFormStepToLabel } from '../../../util/jobOrder.util';
import { Button } from '../../atom';
import { CheckCircle } from '../../atom/Icon/Icon.atom';
import { BodyFourteen } from '../../atom/Text/Text.atom';
import { Modal, Tooltip, UnsavedChangesAlertModal } from '../../molecule';
import StepperSection from '../../template/StepperSectionLayout/StepperSectionLayout';
import JOSubmissionModal from '../JOSubmissionModal/JOSubmissionModal.organism';
import JOFormAssignment from './JOFormAssignment.organism';
import JOFormHeader from './JOFormHeader.organism';
import JOFormSO from './JOFormSO.organism';
import JOFormSOSelectionLocationErrorModal from './JOFormSOSelectionLocationErrorModal.organism';
import JOFormSOSelectionLocationModal from './JOFormSOSelectionLocationModal.organism';

type ContentProps = {
  step: JOFormStep;
  state: UseJOFormStateObj;
  controller: UseJOFormControllerHookObj;
};

type Props = Omit<ContentProps, 'step'>;

const FooterContainer = tw.div`flex justify-between flex-1 items-center`;
const FooterSection = tw.div`flex justify-between gap-3`;
const FooterPrimaryActionCountContainer = tw.p`px-1 bg-white text-orange rounded-md min-w-[25px] ml-2 -mr-2`;

function FormContent({ step, state, controller }: ContentProps) {
  if (step === JOFormStep.HEADER)
    return (
      <JOFormHeader
        actionForm={state.actionForm}
        headerForm={state.headerForm}
      />
    );
  if (step === JOFormStep.SO_SELECTION)
    return <JOFormSO state={state} controller={controller} />;
  return <JOFormAssignment assignment={state.assignmentForm} />;
}

function FooterTooltipContent({
  tooltipMapper,
}: { tooltipMapper: JOFormErrorTooltipItem[] }) {
  return (
    <div tw="bg-white max-w-[300px]">
      <ul tw="list-disc">
        {tooltipMapper.map((item, _idx, arr) => {
          if (arr.length === 1) return <div key={item.key}>{item.element}</div>;
          return (
            <li tw="ml-4" key={item.key}>
              {item.element}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function FooterSubAction({
  label,
  isDisabled,
  tooltipMapper,
  isTooltipVisible,
  tooltipRef,
  handleSubmitDraft,
}: {
  tooltipMapper: JOFormErrorTooltipItem[];
  isTooltipVisible?: boolean;
  isDisabled?: boolean;
  label: string;
  tooltipRef: MutableRefObject<null>;
  handleSubmitDraft: () => void;
}) {
  return (
    <>
      <Tooltip
        withPortal
        variant="light"
        placement="top-start"
        targetRef={tooltipRef}
        visible={isTooltipVisible}
      >
        <FooterTooltipContent tooltipMapper={tooltipMapper} />
      </Tooltip>
      <Button.Outlined
        ref={tooltipRef}
        disabled={isDisabled}
        onClick={handleSubmitDraft}
      >
        {label}
      </Button.Outlined>
    </>
  );
}

function FormFooter({ state, controller }: Omit<ContentProps, 'step'>) {
  const {
    step,
    action,
    draftLabel,
    isDraftDisabled,
    tooltipPrimaryRef,
    tooltipPrimaryMapper,
    tooltipSecondaryMapper,
    tooltipSecondaryRef,
    isToolTipSecondaryVisible,
    isToolTipPrimaryVisible,
    soSelectionAssignedLabel,
    handleSubmitDraft,
  } = useJOFormFooter({
    state,
    controller,
  });
  return (
    <FooterContainer>
      <FooterSection>
        {!state.isSOCandidateFormDisplayed && (
          <FooterSubAction
            label={draftLabel}
            isDisabled={isDraftDisabled}
            tooltipMapper={tooltipSecondaryMapper}
            tooltipRef={tooltipSecondaryRef}
            isTooltipVisible={isToolTipSecondaryVisible}
            handleSubmitDraft={handleSubmitDraft}
          />
        )}
      </FooterSection>
      <FooterSection>
        {step === JOFormStep.SO_SELECTION &&
          !state.isSOCandidateFormDisplayed &&
          !!state.soSelectionForm.selectionByGroup.length && (
            <div tw="flex items-center space-x-2 border-r p-3 border-r-beige-lines">
              {state.locationModalController.assignedLastMileList.length ===
                state.soSelectionForm.selectionDataTotal && (
                <CheckCircle
                  width={18}
                  height={18}
                  fill={theme`colors.status.success.DEFAULT`}
                />
              )}

              <BodyFourteen tw="font-semibold">
                {soSelectionAssignedLabel}
              </BodyFourteen>
            </div>
          )}
        {!!action.secondaryLabel && (
          <Button.Outlined
            disabled={state.isLoading}
            onClick={controller.handleBackHeader}
          >
            {action.secondaryLabel}
          </Button.Outlined>
        )}
        <Button.Solid
          ref={tooltipPrimaryRef}
          css={[
            action.primaryActionDisabledClickable &&
              tw`bg-grey-four cursor-default text-white hover:bg-grey-four`,
          ]}
          disabled={
            action.primaryActionDisabled &&
            !action.primaryActionDisabledClickable
          }
          onClick={action.handleClickPrimary}
        >
          {action.primaryLabel}
          {!!action.primaryActionCount && (
            <FooterPrimaryActionCountContainer>
              {action.primaryActionCount}
            </FooterPrimaryActionCountContainer>
          )}
        </Button.Solid>
      </FooterSection>
      <Tooltip
        withPortal
        variant="light"
        placement="top"
        targetRef={tooltipPrimaryRef}
        visible={isToolTipPrimaryVisible}
      >
        <FooterTooltipContent tooltipMapper={tooltipPrimaryMapper} />
      </Tooltip>
    </FooterContainer>
  );
}

export default function JOForm({ controller, state }: Props) {
  return (
    <StepperSection<JOFormStep>
      isHeaderVisible={
        !(
          controller.formStep === JOFormStep.SO_SELECTION &&
          state.isSOCandidateFormDisplayed
        )
      }
      steps={state.steps}
      currentStep={controller.formStep}
      enumLabelMapper={mapJOFormStepToLabel}
      onClickStep={controller.handleChangeFormState}
      footer={<FormFooter state={state} controller={controller} />}
    >
      <FormContent
        step={controller.formStep}
        controller={controller}
        state={state}
      />

      {state.locationModalController.showDeliveryLocationModal && (
        <JOFormSOSelectionLocationModal
          locationModalController={state.locationModalController}
        />
      )}

      <JOFormSOSelectionLocationErrorModal
        locationModalController={state.locationModalController}
      />

      <UnsavedChangesAlertModal
        isStayOnSubmit={state.blocker.isFormValid}
        title={state.blocker.confirmationObj.title}
        actionLabel={state.blocker.confirmationObj.actionLabel}
        cancelActionLabel={state.blocker.confirmationObj.cancelLabel}
        description={state.blocker.confirmationObj.description}
        isSecondaryValid={state.blocker.confirmationObj.isSecondaryValid}
        hasUnsavedChanges={state.hasUnsavedChanges}
        onActionClick={state.blocker.confirmationObj.handleConfirmAction}
        onCancelActionClick={state.blocker.confirmationObj.handleCancelAction}
      />
      {!!state.actionForm.submissionModal.joSubmissionModalProps && (
        <Modal.WithHeader
          css={tw`p-0 w-[760px]`}
          title={state.actionForm.submissionModal.title}
          onOverlayClick={state.actionForm.submissionModal.handleBack}
          ctaStyle={tw`justify-between`}
          callAction={{
            label: state.actionForm.submissionModal.backActionLabel,
            action: state.actionForm.submissionModal.handleBack,
          }}
        >
          <JOSubmissionModal
            joSubmissionModalProps={
              state.actionForm.submissionModal.joSubmissionModalProps
            }
            joNumberLabel={state.actionForm.submissionModal.joNumberLabel}
            assignedLabel={state.actionForm.submissionModal.assignedLabel}
            descriptionLabel={state.actionForm.submissionModal.descriptionLabel}
          />
        </Modal.WithHeader>
      )}
    </StepperSection>
  );
}
