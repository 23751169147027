import React, { createRef, MutableRefObject, useEffect, useState } from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import tw from 'twin.macro';
import {
  Icon,
  IconButton,
  List,
  OptGroupList,
  OutsideAlerter,
  Popover,
  Portal,
} from '../../atom';
import { OptGroup } from '../../atom/OptGroupList/OptGroupList.atom';
import TextField from '../TextField/TextField.molecule';

type Props = React.ComponentProps<'input'> & {
  label?: string;
  datas?: string[] | OptGroup[];
  changeData?(data: string): void;
  error?: string;
  hideIcon?: boolean;
};

const Container = tw.div`relative`;
const ErrorMessage = tw.div`font-sans text-[12px] leading-[20px] tracking-[0.2px] mt-0.5 mx-4 text-status-alert`;

export default function DropdownForm({
  disabled,
  value,
  children,
  datas,
  error,
  changeData,
  hideIcon,
  ...props
}: Props) {
  const [openDropdown, setOpenDropdown] = useState<boolean>(false);
  const inputRef = createRef<HTMLInputElement>();

  // biome-ignore lint/correctness/useExhaustiveDependencies: on purpose
  useEffect(() => {
    setOpenDropdown(false);
  }, [value]);

  const dataContains =
    value &&
    datas &&
    datas.length &&
    typeof datas[0] === 'string' &&
    (datas as string[])?.includes(value.toString());
  const dataObjectContains =
    value &&
    datas &&
    datas.length &&
    typeof datas[0] !== 'string' &&
    (datas as OptGroup[])?.find(
      (item: OptGroup) =>
        item.groupName === value.toString().split(' > ')[0] &&
        item.items.find(
          (data_item) => data_item.value === value.toString().split(' > ')[1],
        ),
    );

  return (
    <OutsideAlerter
      style={{ width: 'calc( 100% - 200px )' }}
      onClickAway={() => setOpenDropdown(false)}
    >
      <Container ref={inputRef}>
        <TextField
          onFocus={() => (disabled ? null : setOpenDropdown(true))}
          onClick={() => (disabled ? null : setOpenDropdown(true))}
          disabled={disabled}
          value={value || dataContains || dataObjectContains ? value : ''}
          right={
            !hideIcon && (
              <IconButton
                disabled={disabled}
                css={[
                  tw`-mr-2 p-2.5 transform[rotate(90deg)]`,
                  openDropdown && tw`transform[rotate(-90deg)]`,
                ]}
                onClick={(e) => {
                  if (!disabled) {
                    e.stopPropagation();
                    setOpenDropdown(!openDropdown);
                  }
                }}
              >
                <Icon.ChevronSharp tw="w-5 h-5 text-grey-two" />
              </IconButton>
            )
          }
          contentEditable={false}
          {...props}
          onChange={(e) => {
            changeData?.(e.target.value);
          }}
          autoComplete="off"
        />
        <BrowserView>
          <Popover
            visible={openDropdown}
            targetRef={inputRef as MutableRefObject<null>}
            tw="min-w-full z-10 overflow-visible"
            onClick={() => setOpenDropdown(false)}
          >
            {children}
            {datas &&
              !!datas.length &&
              typeof datas[0] === 'string' &&
              !children && (
                <List.Small
                  tw="max-h-80 overflow-auto"
                  options={(datas as string[]).map((option) => ({
                    label: option,
                    value: option,
                  }))}
                  onClickItem={(option) => {
                    changeData?.(option.value);
                    setOpenDropdown(false);
                  }}
                />
              )}
            {datas &&
              !!datas.length &&
              typeof datas[0] !== 'string' &&
              !children && (
                <OptGroupList.Regular
                  tw="max-h-80 overflow-auto"
                  options={datas as OptGroup[]}
                  onClickItem={(option) => {
                    changeData?.(option.value);
                    setOpenDropdown(false);
                  }}
                />
              )}
            {datas && !datas.length && !children && (
              <List.Small
                tw="max-h-80 overflow-auto cursor-not-allowed"
                options={[
                  {
                    label: 'No Data',
                    value: '',
                  },
                ]}
              />
            )}
          </Popover>
        </BrowserView>
        <MobileView>
          <Portal css={openDropdown ? tw`flex` : tw`hidden`}>
            <OutsideAlerter
              onClickAway={() => setOpenDropdown(false)}
              tw="w-full m-4 max-h-[80vh] overflow-auto"
            >
              {children}
              {datas &&
                !!datas.length &&
                typeof datas[0] === 'string' &&
                !children && (
                  <List.Small
                    tw="max-h-80 overflow-auto"
                    options={(datas as string[]).map((option) => ({
                      label: option,
                      value: option,
                    }))}
                    onClickItem={(option) => {
                      changeData?.(option.value);
                      setOpenDropdown(false);
                    }}
                  />
                )}
              {datas &&
                !!datas.length &&
                typeof datas[0] !== 'string' &&
                !children && (
                  <OptGroupList.Regular
                    tw="max-h-80 overflow-auto"
                    options={datas as OptGroup[]}
                    onClickItem={(option) => {
                      changeData?.(option.value);
                      setOpenDropdown(false);
                    }}
                  />
                )}

              {datas && !datas.length && !children && (
                <List.Small
                  tw="max-h-80 overflow-auto"
                  options={[
                    {
                      label: 'No Data',
                      value: '',
                    },
                  ]}
                />
              )}
            </OutsideAlerter>
          </Portal>
        </MobileView>
      </Container>
      {!!error && <ErrorMessage>{error}</ErrorMessage>}
    </OutsideAlerter>
  );
}
