import tw, { styled } from 'twin.macro';

export type InputProps = {
  disabled?: boolean;
};

const Input = styled.input(({ disabled }: InputProps) => [
  tw`m-0 p-4 rounded border border-solid border-beige-lines text-[16px] leading-5 font-sans`,
  tw`focus:(ring-opacity-10 outline-none ring-orange border-orange)`,
  disabled && tw`text-grey-three bg-grey-six border-grey-six`,
]);

export default Input;
